import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { countryCodes, createPhoneCountryCode } from 'lib/phoneUtils'

interface PhoneNumberInputProps {
  countryCode: string
  onCountryCodeChange(e: ChangeEvent<HTMLSelectElement>): void
  phone: string
  onPhoneChange(e: ChangeEvent<HTMLInputElement>): void
  error: string | null
}

const PhoneNumberInput = ({
  countryCode,
  onCountryCodeChange,
  phone,
  onPhoneChange,
  error,
}: PhoneNumberInputProps) => {
  const { t } = useTranslation()

  return (
    <div>
      <div className="relative flex">
        <select
          name="countryCode"
          className="mr-1.5
            inline-block h-10
            w-24
            border-0
            border-b-2
            border-gray-300
            pl-0 text-sm leading-6
            transition
            ease-in-out
            focus:border-primary
            focus:ring-transparent"
          value={countryCode}
          onChange={onCountryCodeChange}
          data-testid="phone-country-code"
        >
          {Object.keys(countryCodes).map(function (key, index) {
            const code = createPhoneCountryCode(key)
            return (
              <option key={index} value={code}>
                {code}
              </option>
            )
          })}
        </select>
        <input
          id="phone"
          name="phone"
          type="tel"
          value={phone}
          className="peer inline-block h-10 grow border-0
            border-b-2 border-gray-300 pl-0
            text-gray-900
            placeholder-transparent
            focus:border-primary
            focus:ring-transparent"
          placeholder={t('form.phone')}
          onChange={onPhoneChange}
          data-testid="phone"
        />

        <label
          htmlFor="phone"
          className="absolute -top-3.5 left-0 text-sm text-gray-600
          transition-all
          peer-placeholder-shown:left-24
          peer-placeholder-shown:top-2
          peer-placeholder-shown:pl-2
          peer-placeholder-shown:text-base
          peer-placeholder-shown:text-gray-400
          peer-focus:-left-0
          peer-focus:-top-3.5
          peer-focus:pl-0
          peer-focus:text-sm
          peer-focus:text-gray-600"
        >
          {t('form.phone')}
        </label>
      </div>
      {error && <div className="text-xs text-red-400">{error}</div>}
      <div className="text-xs text-gray-400">{t('checkout.phonePrompt')}</div>
    </div>
  )
}

export default PhoneNumberInput

import { useMutation } from '@apollo/client'
import { useState, useRef, RefObject } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  CREDITS_PATH,
  MEMBERSHIPS_PATH,
  MANAGE_MEMBERSHIP_PATH,
  LOGIN_PATH,
} from 'Routes'
import { useBusiness } from 'business/use-business'
import { Business, User } from 'generated/graphql'
import { SIGN_OUT_MUTATION } from 'graphql/SignOut'
import useOnClickOutside from 'hooks/useOnClickOutside'
import useWindowDimensions from 'hooks/useWindowDimensions'
import { useLogin } from 'login/use-login'
import BusinessLogoImage from 'shared/components/BusinessLogoImage'
import { isInFrame } from 'shared/helpers/iframe'
import CloseIcon from './CloseIcon'

const UserIcon = ({ size, user }: { size: 'small' | 'large'; user?: User }) => {
  return (
    <>
      {user?.firstName ? (
        <div
          className={`${size == 'small' ? 'size-8 bg-primary text-lg text-white' : 'size-32 bg-white text-8xl font-light text-primary'} flex items-center justify-center rounded-full`}
        >
          {user.firstName[0]}
        </div>
      ) : (
        <div
          className={
            user
              ? 'text-primary'
              : size == 'small'
                ? 'text-gray-200'
                : 'text-white'
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="48 48 416 416"
            className={size == 'small' ? 'size-8' : 'size-32'}
            fill="currentColor"
          >
            <path d="M256,48C141.31,48,48,141.31,48,256s93.31,208,208,208,208-93.31,208-208S370.69,48,256,48Zm2,96a72,72,0,1,1-72,72A72,72,0,0,1,258,144Zm-2,288a175.55,175.55,0,0,1-129.18-56.6C135.66,329.62,215.06,320,256,320s120.34,9.62,129.18,55.39A175.52,175.52,0,0,1,256,432Z" />
          </svg>
        </div>
      )}
    </>
  )
}
interface UserProfileButtonProps {
  onLogOut?: () => void
  testid?: string
}

// Slide in panel example https://codepen.io/devdojo/pen/NWyPrGe

const UserProfileButton = ({
  onLogOut,
  testid = 'user-profile-button',
}: UserProfileButtonProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { isMobile } = useWindowDimensions()
  const { business } = useBusiness()
  const { user, unsetUser } = useLogin()
  const buttonRef = useRef<HTMLButtonElement>(null)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const [menuOpen, setMenuOpen] = useState(false)
  const [signOut, { loading: signingOut }] = useMutation(SIGN_OUT_MUTATION)
  const creditBalance = user ? user.availableCredits.length : 0
  const creditBundles = business ? business.bookingOptions.creditBundles : []
  const showRemainingCredits =
    user && (creditBundles.length > 0 || creditBalance > 0)
  const activeMembership: boolean = user && user.membership !== null
  const membershipPlans = business
    ? business.bookingOptions.membershipPlans
    : []
  useOnClickOutside(dropdownRef, buttonRef, () => setMenuOpen(false))

  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
  }

  const startSignOut = async () => {
    setMenuOpen(false)

    if (!signingOut) {
      const result = await signOut({
        variables: {
          input: {},
        },
      })

      if (result.data?.signOut?.status !== 'success') {
        console.error('Sign-out failed.', result)
      }

      unsetUser()

      if (onLogOut) {
        onLogOut()
      }
    }
  }

  const logInOrSignUp = () => {
    navigate(LOGIN_PATH, {
      replace: false,
      state: {
        referrerPath: location.pathname,
      },
    })
  }

  const viewMembershipOptions = () => {
    toggleMenu()

    navigate(MEMBERSHIPS_PATH, {
      replace: false,
      state: {
        referrerPath: location.pathname,
      },
    })
  }

  const manageMembership = () => {
    toggleMenu()

    navigate(MANAGE_MEMBERSHIP_PATH, {
      replace: false,
    })
  }

  const buyCredits = () => {
    toggleMenu()

    navigate(CREDITS_PATH, {
      replace: false,
      state: {
        referrerPath: location.pathname,
      },
    })
  }

  const UserProfileDropDownMenu = ({
    innerRef,
  }: {
    innerRef: RefObject<HTMLDivElement>
  }) => {
    const optionClasses =
      'block w-full text-left px-4 py-2 text-sm hover:bg-primary hover:text-white'
    const loggedInClasses = 'border-0 border-t-[0.5px] border-gray-300'

    const options = () => {
      if (user) {
        return (
          <div>
            {user.membership ? (
              <button
                onClick={manageMembership}
                className={[optionClasses, loggedInClasses].join(' ')}
              >
                {t('title.manageMemberships')}
              </button>
            ) : membershipPlans.length > 0 ? (
              <button
                onClick={viewMembershipOptions}
                className={[optionClasses, loggedInClasses].join(' ')}
              >
                {t('title.memberships')}
              </button>
            ) : null}
            {creditBundles.length > 0 && (
              <button
                onClick={buyCredits}
                className={[optionClasses, loggedInClasses].join(' ')}
              >
                {t('title.credits')}
              </button>
            )}
            <button
              onClick={startSignOut}
              className={[
                optionClasses,
                loggedInClasses,
                'hover:rounded-b-lg',
              ].join(' ')}
            >
              {t('login.logOut')}
            </button>
          </div>
        )
      } else {
        return (
          <button
            onClick={logInOrSignUp}
            className={[optionClasses, 'hover:rounded-lg'].join(' ')}
          >
            {t('button.logInOrSignUp')}
          </button>
        )
      }
    }

    return (
      <div
        ref={innerRef}
        className="absolute top-12 z-10 m-2 w-64 rounded-lg bg-white shadow-xl"
      >
        {user && (
          <div className="my-4 flex flex-col text-primary">
            <div className="self-center">
              <UserIcon size="small" user={user} />
            </div>

            <div className="mt-4 flex flex-col px-4 text-center text-xs">
              {user.email && (
                <div className="mb-2 text-black">
                  <div className="overflow-hidden text-ellipsis whitespace-nowrap">
                    {user.email}
                  </div>
                </div>
              )}

              {showRemainingCredits && (
                <div className="text-xs">
                  {t('credits.creditsRemaining', {
                    count: creditBalance,
                  })}
                </div>
              )}

              {activeMembership && (
                <div className="text-xs">
                  {t('memberships.activeMembership')}
                </div>
              )}
            </div>
          </div>
        )}
        {options()}
      </div>
    )
  }

  return isInFrame ? null : (
    <div className="relative flex flex-col md:items-end">
      <button
        ref={buttonRef}
        className="z-10 ml-2 flex max-w-[15rem] items-center text-white md:m-0"
        onClick={toggleMenu}
        data-testid={testid}
        data-loggedin={user === false ? 'false' : 'true'}
      >
        <div
          className={`${user ? 'text-primary' : 'text-gray-300'} self-center bg-white`}
        >
          <UserIcon size="small" user={user ? user : undefined} />
        </div>
      </button>
      {!isMobile && menuOpen && (
        <UserProfileDropDownMenu innerRef={dropdownRef} />
      )}
      {isMobile && (
        <div
          className={`fixed right-0 top-0 z-10 h-screen w-screen bg-white transition-all duration-300 ease-in ${
            menuOpen ? 'translate-x-0' : '-translate-x-full'
          }`}
        >
          <div className="relative h-full">
            <div
              className={`bg-primary px-8 pt-12 ${
                showRemainingCredits ? 'pb-4' : 'pb-12'
              }`}
            >
              <CloseIcon onClick={toggleMenu} textColor="text-white" />
              <div className="flex flex-col justify-center">
                <div className="mb-4 self-center">
                  <UserIcon size="large" user={user ? user : undefined} />
                </div>

                <div className="text-center text-lg text-white">
                  {user && user.email && (
                    <div className="mb-2 text-base">
                      <div className="overflow-hidden text-ellipsis whitespace-nowrap">
                        {user.email}
                      </div>
                    </div>
                  )}

                  {showRemainingCredits && (
                    <div>
                      {t('credits.creditsRemaining', {
                        count: creditBalance,
                      })}
                    </div>
                  )}

                  {activeMembership && (
                    <div>{t('memberships.activeMembership')}</div>
                  )}
                </div>
              </div>
            </div>
            <div className="mx-4 my-8 flex flex-col space-y-4">
              {user && user.membership ? (
                <button onClick={manageMembership} className="button-secondary">
                  {t('title.manageMemberships')}
                </button>
              ) : user && membershipPlans.length > 0 ? (
                <button
                  onClick={viewMembershipOptions}
                  className="button-secondary"
                >
                  {t('title.memberships')}
                </button>
              ) : null}
              {user && creditBundles.length > 0 && (
                <button onClick={buyCredits} className="button-secondary">
                  {t('title.credits')}
                </button>
              )}
              {user && (
                <button onClick={startSignOut} className="button-secondary">
                  {t('login.logOut')}
                </button>
              )}
              {!user && (
                <button onClick={logInOrSignUp} className="button-secondary">
                  {t('login.logIn')}
                </button>
              )}
            </div>
            <div className="absolute bottom-6 flex w-full  justify-center">
              <BusinessLogoImage business={business as Business} />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default UserProfileButton

import clsx from 'clsx'
import { useContext, useEffect } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import {
  CREDITS_PATH,
  MEMBERSHIPS_PATH,
  CHECKOUT_PATH,
  LOGIN_PATH,
  SCHEDULE_PATH,
  MANAGE_MEMBERSHIP_PATH,
} from 'Routes'
import { useBusiness } from 'business/use-business'
import { CartContext } from 'context/CartProvider'
import { CheckoutContext } from 'context/CheckoutProvider'
import { User, Money } from 'generated/graphql'
import useWindowDimensions from 'hooks/useWindowDimensions'
import { formatDate } from 'lib/dateUtils'
import { removeIcon, bagIcon } from 'lib/icons'
import { formatMoney } from 'lib/moneyUtils'
import { useLogin } from 'login/use-login'
import { LoginValueProp } from 'pages/login/Login'
import { DateFilterType } from 'pages/schedule/DateFilterType'
import CheckoutLayout from 'shared/components/CheckoutLayout'
import PageLayout from 'shared/components/PageLayout'
import {
  CartItem,
  CreditRedemption,
  MembershipBooking,
} from 'shared/constants/cart'
import { isInFrame } from 'shared/helpers/iframe'

interface LocationState {
  dateFilter: DateFilterType
  date: Date
  week: Array<Date>
}

const ActionButton = ({
  user,
  loginOrSignup,
  startCheckout,
}: {
  user: User | false
  loginOrSignup: () => void
  startCheckout: () => void
}) => {
  const { t } = useTranslation()

  return (
    <div className="relative my-4 md:my-0">
      <button onClick={() => startCheckout()} className="button-primary">
        {user || isInFrame ? t('button.checkout') : t('button.guestCheckout')}
      </button>

      {!user && !isInFrame && (
        <>
          <div className="inline-flex w-full items-center justify-center">
            <hr className="my-5 h-[1px] w-64 rounded border-0 bg-gray-300" />
            <div className="absolute left-1/2 -translate-x-1/2 bg-white px-4 text-sm">
              or
            </div>
          </div>
          <button onClick={loginOrSignup} className="button-primary-outline">
            {t('button.logInOrSignUp')}
          </button>
        </>
      )}
    </div>
  )
}

interface CartItemsProps {
  cartItems: Array<CartItem>
  cartTotal: Money | 0
  creditRedemptions: Array<CreditRedemption>
  membershipBookings: Array<MembershipBooking>
  freeTrialRedemption: string | null
  editable: boolean
  removeItem?: (item: CartItem) => void
}

export const CartItems = ({
  cartItems,
  cartTotal,
  creditRedemptions,
  membershipBookings,
  freeTrialRedemption,
  editable,
  removeItem,
}: CartItemsProps) => {
  const { t, i18n } = useTranslation()

  const creditsRedemptionsForItem = (cartItemId: string) => {
    const creditRedemption = creditRedemptions.find((item) => {
      return item.id === cartItemId
    })

    return creditRedemption ? creditRedemption.credits : 0
  }

  const bookedWithMembership = (cartItemId: string) => {
    return membershipBookings.find((item) => {
      return item.id === cartItemId
    })
  }

  const creditsRedeemed = creditRedemptions.reduce(
    (accumulator, redemption) => {
      return accumulator + redemption.credits
    },
    0
  )

  const itemPrice = ({
    item,
    trialPass,
    membership,
    credits,
  }: {
    item: CartItem
    trialPass: boolean
    membership: boolean
    credits: number
  }) => {
    const lineItemPrice: Money = {
      amount: item.price.amount * (item.quantity - credits),
      currency: item.price.currency,
    }

    if (membership) {
      lineItemPrice.amount -= item.price.amount
    }

    if (trialPass) {
      lineItemPrice.amount -= item.price.amount
    }

    if (trialPass) {
      return (
        <div>
          {lineItemPrice.amount > 0 && (
            <>
              <div>{formatMoney(lineItemPrice, i18n.resolvedLanguage)}</div>
              <span>{' + '}</span>
            </>
          )}
          <span className={clsx(lineItemPrice.amount > 0 && 'text-sm')}>
            {t('trialPass.freeTrial')}
          </span>

          {membership && <div className="text-sm">{`+ ${t('member')}`}</div>}

          {credits > 0 && (
            <div className="text-sm">
              {`+ ${t('credits.totalCredits', { count: credits })}`}
            </div>
          )}
        </div>
      )
    }

    if (membership) {
      return (
        <div>
          {lineItemPrice.amount > 0 && (
            <>
              <div>{formatMoney(lineItemPrice, i18n.resolvedLanguage)}</div>
              <span>{' + '}</span>
            </>
          )}
          <span className={clsx(lineItemPrice.amount > 0 && 'text-sm')}>
            {t('member')}
          </span>
          {credits > 0 && (
            <div className="text-sm">
              {`+ ${t('credits.totalCredits', { count: credits })}`}
            </div>
          )}
        </div>
      )
    }

    if (credits > 0) {
      return (
        <div>
          {lineItemPrice.amount > 0 && (
            <>
              <div>{formatMoney(lineItemPrice, i18n.resolvedLanguage)}</div>
              <span>{' + '}</span>
            </>
          )}
          <span className={clsx(lineItemPrice.amount > 0 && 'text-sm')}>
            {t('credits.totalCredits', { count: credits })}
          </span>
        </div>
      )
    }

    return (
      <div>
        {formatMoney(
          {
            amount: item.price.amount * (item.quantity - credits),
            currency: item.price.currency,
          },
          i18n.resolvedLanguage
        )}
      </div>
    )
  }

  const items = cartItems
    .sort(
      (a, b) =>
        new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
    )
    .map((item: CartItem, index: number) => {
      const credits = creditsRedemptionsForItem(item.id!)
      const trialPass = item.id! === freeTrialRedemption
      const imageUrl = item.image
        ? item.image
        : import.meta.env.VITE_APP_EVENT_IMAGE_DEFAULT

      return (
        <div
          key={index}
          className="mb-3 flex gap-2 border-b border-gray-300 pb-2"
        >
          <img
            className="h-[72px] w-[72px] rounded-lg md:block"
            src={imageUrl}
            alt={item.name}
          />
          <div className="w-full">
            <div className="flex flex-col">
              <div className="flex justify-between">
                <div className="flex flex-1 flex-col font-semibold">
                  <div className="line-clamp-2">
                    <span
                      className={`${
                        item.quantity === 1 ? 'hidden' : 'mr-1 text-primary'
                      }`}
                    >
                      {item.quantity}x
                    </span>
                    {item.name}
                  </div>
                </div>
                <div className="flex min-w-20 flex-col text-right font-semibold">
                  {itemPrice({
                    item,
                    trialPass,
                    membership: bookedWithMembership(item.id!) !== undefined,
                    credits,
                  })}
                </div>
              </div>
              <div className="flex justify-between">
                <div className="flex items-center text-sm font-normal uppercase">{`${formatDate(
                  {
                    date: new Date(item.startTime),
                    format: 'short',
                    timeZone: item.timeZone,
                    locale: i18n.resolvedLanguage,
                  }
                )}`}</div>
                <div className="flex">
                  {editable && removeItem && (
                    <button
                      className="mt-1 flex items-center gap-1 text-sm text-gray-400"
                      onClick={() => removeItem(item)}
                    >
                      <div className="italic">{t('button.removeEvent')}</div>
                      {removeIcon()}{' '}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    })

  return (
    <>
      {items}
      {editable && (
        <Link to={SCHEDULE_PATH} className="my-3 block text-primary underline">
          {t('basket.addAnotherEvent')}
        </Link>
      )}
      <div className="flex justify-between font-bold">
        <div className="uppercase">{t('total')}</div>
        <div className="flex flex-col">
          <div className="text-right">
            {formatMoney(cartTotal, i18n.resolvedLanguage)}
          </div>
          {creditsRedeemed > 0 && (
            <div className="text-right font-semibold">
              {(cartTotal && cartTotal.amount > 0 ? '+ ' : '') +
                t('credits.totalCredits', {
                  count: creditsRedeemed,
                })}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export const EmptyCart = () => {
  const { t } = useTranslation()

  return (
    <div className="mt-24">
      <div className="flex flex-col items-center">
        <div className="text-primary">{bagIcon()}</div>
        <div>{t('basket.empty')}</div>
        <Link
          to={SCHEDULE_PATH}
          className="mt-4 block text-center text-primary underline"
        >
          {t('basket.viewEventsSchedule')}
        </Link>
      </div>
    </div>
  )
}

const Cart = () => {
  const { i18n, t } = useTranslation()
  const navigate = useNavigate()
  const { isMobile } = useWindowDimensions()
  const { business } = useBusiness()
  const { setCheckout } = useContext(CheckoutContext)
  const { user, refetchUser, loading: userLoading } = useLogin()
  const location = useLocation()
  const locationState = (location.state as LocationState) || {}
  const { cart, removeFromCart } = useContext(CartContext)
  const membership = user && user.membership ? user.membership : null
  const {
    cartItems,
    cartTotal,
    creditRedemptions,
    membershipBookings,
    membershipQuotaExceeded,
    freeTrialRedemption,
  } = cart

  useEffect(() => {
    refetchUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const startCheckout = () => {
    setCheckout({ total: cartTotal })

    navigate(CHECKOUT_PATH, {
      replace: false,
    })
  }

  const loginOrSignup = () => {
    navigate(LOGIN_PATH, {
      replace: false,
      state: {
        referrerPath: location.pathname,
      },
    })
  }

  const close = () => {
    let state = {}

    if (locationState) {
      const { dateFilter, date, week } = locationState
      state = {
        dateFilter,
        date,
        week,
      }
    }

    navigate(SCHEDULE_PATH, {
      replace: true,
      state,
    })
  }

  const MembershipStatus = () => {
    return membership ? (
      membership.paymentOverdue ? (
        <div className="mb-8 rounded bg-gray-100 p-4 text-sm">
          <div>
            <span className="text-primary">
              {t('basket.membershipStatus.membershipSuspended')}
            </span>{' '}
            {t('basket.membershipStatus.paymentFailed')}
          </div>
          <div className="mt-4">
            <Trans
              i18nKey="basket.membershipStatus.addNewPaymentCard"
              components={{
                billingPortalLink: (
                  <Link
                    to={MANAGE_MEMBERSHIP_PATH}
                    className="text-primary underline underline-offset-2"
                  >
                    here
                  </Link>
                ),
              }}
            />
          </div>
        </div>
      ) : membershipQuotaExceeded ? (
        <div className="mb-8 rounded bg-gray-100 p-4 text-sm">
          <div className="text-primary">
            {t('basket.membershipStatus.zeroBookingsRemaining')}
          </div>
          <div className="mt-4">
            {t('basket.membershipStatus.quotaDepleted')}
          </div>
          {!membership.stripeSubscription.cancelAtPeriodEnd && (
            <div className="mt-4">
              {t('basket.membershipStatus.renewalDate', {
                renewalDate: formatDate({
                  date: new Date(
                    membership.stripeSubscription.currentPeriodEnd
                  ),
                  format: 'date',
                  timeZone: business ? business.timeZone : null,
                  locale: i18n.resolvedLanguage,
                }),
              })}
            </div>
          )}
        </div>
      ) : null
    ) : null
  }

  const alternativeActions = () => {
    if (isInFrame) {
      return null
    }

    if (user && business) {
      const result = []

      if (business.bookingOptions?.membershipPlans.length > 0) {
        result.push(
          <Link
            key="member"
            to={MEMBERSHIPS_PATH}
            state={{ referrerPath: location.pathname }}
            className="mt-3 block text-center text-primary underline"
          >
            {t('basket.becomeMember')}
          </Link>
        )
      }

      if (business.bookingOptions?.creditBundles.length > 0) {
        result.push(
          <Link
            key="credits"
            to={CREDITS_PATH}
            state={{ referrerPath: location.pathname }}
            className="mt-3 block text-center text-primary underline"
          >
            {t('basket.buyEventCredits')}
          </Link>
        )
      }

      return result || null
    } else {
      return (
        <>
          <hr className="my-5 h-[1px] bg-gray-300" />
          <LoginValueProp business={business} />
        </>
      )
    }
  }

  return (
    <PageLayout pageLoading={userLoading}>
      {cartItems.length > 0 ? (
        <CheckoutLayout
          title={t('title.basket')}
          onBack={close}
          left={{
            header: <MembershipStatus />,
            title: t('basket.yourBasket'),
            content: (
              <>
                <CartItems
                  cartItems={cartItems}
                  cartTotal={cartTotal}
                  creditRedemptions={creditRedemptions}
                  membershipBookings={membershipBookings}
                  freeTrialRedemption={freeTrialRedemption}
                  editable={true}
                  removeItem={removeFromCart}
                />
                <div className="md:hidden">
                  <ActionButton
                    user={user}
                    loginOrSignup={loginOrSignup}
                    startCheckout={startCheckout}
                  />

                  {alternativeActions()}
                </div>
              </>
            ),
          }}
          right={{
            hidden: isMobile,
            content: (
              <>
                <ActionButton
                  user={user}
                  loginOrSignup={loginOrSignup}
                  startCheckout={startCheckout}
                />
                {alternativeActions()}
              </>
            ),
          }}
        />
      ) : (
        <EmptyCart />
      )}
    </PageLayout>
  )
}

export default Cart

import { ApolloClient, useApolloClient } from '@apollo/client'
import { useEffect, useReducer } from 'react'
import { Business } from 'generated/graphql'
import { CURRENT_BUSINESS_QUERY } from 'graphql/Business'

type State =
  | { value: 'valid'; context: { business: Business } }
  | { value: 'invalid'; context: { errors?: Array<string> } }
  | { value: 'pending.idle'; context: Record<string, unknown> }
  | { value: 'pending.refresh' }

type Action =
  | { type: 'START' }
  | { type: 'REFRESH' }
  | { type: 'REFRESH.SUCCESS'; payload: Business }
  | { type: 'REFRESH.ERROR' }

const action = (type: string, payload?: unknown) =>
  ({ type, payload }) as Action

const reducer = (state: State, action: Action): State => {
  // console.log(`business reducer, state: ${state.value}, action: ${action.type}`)

  switch (state.value) {
    case 'invalid':
      switch (action.type) {
        case 'REFRESH':
          return {
            value: 'pending.refresh',
          }
      }
      break
    case 'pending.idle':
      switch (action.type) {
        case 'START':
          return {
            value: 'pending.refresh',
          }
      }
      break
    case 'pending.refresh':
      switch (action.type) {
        case 'REFRESH.SUCCESS':
          return {
            value: 'valid',
            context: {
              business: action.payload,
            },
          }
        case 'REFRESH.ERROR':
          return {
            value: 'invalid',
            context: {},
          }
      }
      break
  }
  return { ...state }
}

const getCurrentBusiness = async (
  graphqlClient: ApolloClient<any>
): Promise<Business> => {
  const result = await graphqlClient.query({
    query: CURRENT_BUSINESS_QUERY,
  })

  // console.log('query CurrentBusiness result', result)

  return result.data.currentBusiness
}

export const useBusinessManager = (
  defaultState: State = {
    value: 'pending.idle',
    context: {},
  }
) => {
  const graphqlClient = useApolloClient()
  const [state, dispatch] = useReducer<typeof reducer>(reducer, defaultState)

  useEffect(() => {
    // console.log('business manager state: ' + state.value)

    switch (state.value) {
      case 'pending.refresh':
        getCurrentBusiness(graphqlClient)
          .then((business) => {
            dispatch(action('REFRESH.SUCCESS', business))
          })
          .catch(() => dispatch(action('REFRESH.ERROR')))

        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.value])

  return {
    business: state.value === 'valid' && state.context.business,
    errors: state.value === 'invalid' ? state.context.errors : [],
    start: () => dispatch(action('START')),
    loading: state.value === 'pending.refresh',
    refetch: async (): Promise<void> => {
      getCurrentBusiness(graphqlClient)
        .then((business) => {
          dispatch(action('REFRESH_USER_DATA', business))
        })
        .catch(() => dispatch(action('REFRESH.ERROR')))
    },
  }
}

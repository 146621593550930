import { useState, useEffect } from 'react'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from 'tailwind.config.js'

function getWindowDimensions() {
  const { screenX, screenY, innerWidth: width, innerHeight: height } = window
  const tailwindTheme = resolveConfig(tailwindConfig).theme
  const breakpointMd = parseInt(tailwindTheme.screens['md'].slice(0, -2))

  return {
    screenX,
    screenY,
    windowWidth: width,
    windowHeight: height,
    isMobile: width <= breakpointMd,
  }
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}

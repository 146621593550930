import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { CART_PATH } from 'Routes'
import { CartContext } from 'context/CartProvider'
import { formatMoney } from 'lib/moneyUtils'

const Basket = () => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const { cart } = useContext(CartContext)
  const { cartItems, cartTotal } = cart
  const itemCount = cartItems.length

  function viewBasket() {
    navigate(CART_PATH, {
      replace: false,
    })
  }

  if (itemCount === 0) {
    return null
  }

  return (
    <div className="fixed bottom-0 h-28 w-full bg-white p-3 pt-2 shadow-[0_0_6px_2px_rgba(0,0,0,0.1)] md:hidden">
      <div className="flex h-full flex-col justify-center">
        <div className="mb-2 text-center text-sm">
          {t('schedule.basketPrompt')}
        </div>
        <div className="flex justify-between font-bold">
          <div className="300 flex items-center">
            <div className="flex h-10 w-10 items-center justify-center rounded-md bg-primary text-white">
              {itemCount}
            </div>

            <div className="ml-4 text-lg">
              {cartTotal &&
                cartTotal.amount > 0 &&
                formatMoney(cartTotal, i18n.resolvedLanguage)}
            </div>
          </div>
          <div>
            <button className="button-primary w-40" onClick={viewBasket}>
              {t('button.viewBasket')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Basket

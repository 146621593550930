import { gql } from '@apollo/client'

// Mutations
export const CREATE_MEMBERSHIP_CHECKOUT_URL_MUTATION = gql`
  mutation CreateMembershipCheckoutUrl(
    $membershipPlanId: ID!
    $checkoutUrls: CheckoutUrlsInput!
  ) {
    createMembershipCheckoutUrl(
      input: {
        membershipPlanId: $membershipPlanId
        checkoutUrls: $checkoutUrls
      }
    ) {
      url
    }
  }
`

// Mutations
export const CREATE_BILLING_PORTAL_URL_MUTATION = gql`
  mutation CreateBillingPortalUrl($returnUrl: String!) {
    createBillingPortalUrl(input: { returnUrl: $returnUrl }) {
      url
    }
  }
`

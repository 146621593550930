import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useBusiness } from 'business/use-business'
import { Business } from 'generated/graphql'
import DesktopHeader, {
  DesktopHeaderProps,
} from 'shared/components/DesktopHeader'
import Spinner from 'shared/components/Spinner'

const AccountIdError = () => {
  const { t } = useTranslation()
  return (
    <div className="p-6 text-center md:min-h-screen">
      <div className="">
        <div className="mb-6 text-2xl font-bold">
          {t('businessError.headline')}
        </div>
        <div>{t('businessError.message')}</div>
      </div>
      <img
        src="https://bookable.ams3.cdn.digitaloceanspaces.com/brand/logo.png"
        alt="bookable"
        className="mt-8 inline-block h-5"
      />
    </div>
  )
}

const PageLayout = ({
  headerProps,
  pageLoading = false,
  children,
}: {
  headerProps?: Omit<DesktopHeaderProps, 'business'>
  pageLoading?: boolean

  children: ReactNode
}) => {
  const { loading: loadingBusiness, business } = useBusiness()

  return (
    <>
      {loadingBusiness ? (
        <div className="text-gray h-screen">
          <Spinner />
        </div>
      ) : !business ? (
        <AccountIdError />
      ) : (
        <>
          <DesktopHeader
            {...{
              ...headerProps,
              ...{ business: business as Business },
            }}
          />
          {pageLoading ? (
            <div className="h-screen text-primary">
              <Spinner />
            </div>
          ) : (
            <>{children}</>
          )}
        </>
      )}
    </>
  )
}

export default PageLayout

import { CreditBundle } from 'generated/graphql'

export const creditBundleInputFromCreditBundle = (
  creditBundle: CreditBundle
) => {
  return (({ id, totalCredits, creditValidityMonths, price }) => ({
    id,
    totalCredits,
    creditValidityMonths,
    priceCents: Math.round(price.amount * 100),
  }))(creditBundle)
}

import { createContext, ReactNode } from 'react'
import { useLoginManager } from 'login/use-login-manager'

export const LoginContext = createContext<ReturnType<typeof useLoginManager>>({
  user: false,
  loading: false,
  errors: [],
  setUser: () => {},
  unsetUser: () => {},
  refetchUser: () => {
    return Promise.resolve()
  },
})

export const LoginProvider = ({ children }: { children: ReactNode }) => {
  const loginManager = useLoginManager()

  return (
    <LoginContext.Provider value={loginManager}>
      {children}
    </LoginContext.Provider>
  )
}

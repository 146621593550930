import { useTranslation } from 'react-i18next'
import PhoneNumberInput from 'pages/form/PhoneNumberInput'
import TextInput from 'pages/form/TextInput'
import {
  CHECKOUT_FORM_EMAIL,
  CHECKOUT_FORM_FIRST_NAME,
  CHECKOUT_FORM_LAST_NAME,
} from 'shared/constants/checkout'
import {
  CheckoutFormData,
  CheckoutFormValidationErrors,
} from 'shared/constants/checkout'

const CheckoutForm = ({
  formData,
  validationErrors,
  setFormData,
}: {
  formData: CheckoutFormData
  validationErrors: CheckoutFormValidationErrors
  setFormData: (formData: CheckoutFormData) => void
}) => {
  const { t } = useTranslation()

  const getValidationError = (key: string | null) => {
    return key ? t(key) : null
  }

  return (
    <div className="flex flex-col gap-6">
      <TextInput
        name={CHECKOUT_FORM_FIRST_NAME}
        value={formData.firstName}
        autocomplete="given-name"
        placeholder={t('form.firstName')}
        onChange={(e) =>
          setFormData({ ...formData, firstName: e.target.value })
        }
        error={getValidationError(validationErrors.firstName)}
      />
      <TextInput
        name={CHECKOUT_FORM_LAST_NAME}
        value={formData.lastName}
        autocomplete="family-name"
        placeholder={t('form.lastName')}
        onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
        error={getValidationError(validationErrors.lastName)}
      />
      <TextInput
        name={CHECKOUT_FORM_EMAIL}
        value={formData.email}
        autocomplete="email"
        type="email"
        placeholder={t('form.email')}
        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
        error={getValidationError(validationErrors.email)}
        comment={t('checkout.emailPrompt')}
      />

      <PhoneNumberInput
        countryCode={formData.phoneCountryCode}
        onCountryCodeChange={(e) =>
          setFormData({ ...formData, phoneCountryCode: e.target.value })
        }
        phone={formData.phoneNumber}
        onPhoneChange={(e) =>
          setFormData({ ...formData, phoneNumber: e.target.value })
        }
        error={getValidationError(validationErrors.phone)}
      />
    </div>
  )
}

export default CheckoutForm

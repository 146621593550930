export const CHECKOUT_FORM_EMAIL = 'email'
export const CHECKOUT_FORM_FIRST_NAME = 'firstName'
export const CHECKOUT_FORM_LAST_NAME = 'lastName'
export const CHECKOUT_FORM_PHONE_COUNTRY_CODE = 'phoneCountryCode'
export const CHECKOUT_FORM_PHONE = 'phone'

export interface CheckoutFormData {
  firstName: string
  lastName: string
  email: string
  phoneCountryCode: string
  phoneNumber: string
}

export interface CheckoutFormValidationErrors {
  firstName: string | null
  lastName: string | null
  email: string | null
  phone: string | null
}

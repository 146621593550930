import { gql } from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: { input: any; output: any; }
  /** Represents untyped JSON */
  JSON: { input: any; output: any; }
};

export type AccountSetupStatus = {
  __typename?: 'AccountSetupStatus';
  complete: Scalars['Boolean']['output'];
  setupTasks?: Maybe<AccountSetupTasks>;
};

export type AccountSetupTasks = {
  __typename?: 'AccountSetupTasks';
  brand: Scalars['Boolean']['output'];
  email: Scalars['Boolean']['output'];
  events: Scalars['Boolean']['output'];
  payments: Scalars['Boolean']['output'];
};

export type AwsPresignedPost = {
  __typename?: 'AwsPresignedPost';
  fields: Scalars['JSON']['output'];
  url: Scalars['String']['output'];
};

export type BookedEvent = {
  __typename?: 'BookedEvent';
  cancelled?: Maybe<Scalars['Boolean']['output']>;
  description: Scalars['String']['output'];
  endTime: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  images: Array<EventImage>;
  locationName?: Maybe<Scalars['String']['output']>;
  locationUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  startTime: Scalars['ISO8601DateTime']['output'];
  timeZone: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type Booking = {
  __typename?: 'Booking';
  bookingOrder: BookingOrder;
  cancellationPeriod: Scalars['Int']['output'];
  cancellationReason?: Maybe<BookingCancellationReason>;
  cancelled: Scalars['Boolean']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  event: Event;
  eventId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  membership?: Maybe<Membership>;
  pricePaid: Money;
  quantity: Scalars['Int']['output'];
  recurringEventInstance?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

export enum BookingCancellationReason {
  BusinessCancelled = 'BUSINESS_CANCELLED',
  EventCancelled = 'EVENT_CANCELLED',
  MembershipCancelled = 'MEMBERSHIP_CANCELLED',
  MembershipExpiredUnpaid = 'MEMBERSHIP_EXPIRED_UNPAID',
  NotPermitted = 'NOT_PERMITTED',
  UserCancelled = 'USER_CANCELLED'
}

export type BookingOptions = {
  __typename?: 'BookingOptions';
  creditBundles: Array<CreditBundle>;
  membershipPlans: Array<MembershipPlan>;
  trialPassEnabled: Scalars['Boolean']['output'];
};

export type BookingOrder = {
  __typename?: 'BookingOrder';
  id: Scalars['ID']['output'];
  payment?: Maybe<Payment>;
  reference: Scalars['String']['output'];
  status: Scalars['String']['output'];
  user: User;
};

export type BookingOrderResponse = {
  __typename?: 'BookingOrderResponse';
  bookingOrder: BookingOrder;
  paymentInstructions?: Maybe<Scalars['String']['output']>;
  paymentRedirectUrl?: Maybe<Scalars['String']['output']>;
  stripeAccountId?: Maybe<Scalars['String']['output']>;
  stripeClientSecret?: Maybe<Scalars['String']['output']>;
};

export type Business = {
  __typename?: 'Business';
  accountId: Scalars['String']['output'];
  bookingCancellationPeriod: Scalars['Int']['output'];
  bookingOptions: BookingOptions;
  color?: Maybe<Scalars['String']['output']>;
  countryCode: Scalars['String']['output'];
  currencyCode: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  facebook?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  instagram?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  paymentConfig: BusinessPaymentConfig;
  phone?: Maybe<Scalars['String']['output']>;
  phoneCountryCode?: Maybe<Scalars['String']['output']>;
  tiktok?: Maybe<Scalars['String']['output']>;
  timeZone: Scalars['String']['output'];
  website?: Maybe<Scalars['String']['output']>;
  whatsapp?: Maybe<Scalars['String']['output']>;
  youtube?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of BusinessCancelBooking */
export type BusinessCancelBookingInput = {
  bookingId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of BusinessCancelBooking. */
export type BusinessCancelBookingPayload = {
  __typename?: 'BusinessCancelBookingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
};

export type BusinessPaymentConfig = {
  __typename?: 'BusinessPaymentConfig';
  billingCard?: Maybe<PaymentCard>;
  id: Scalars['ID']['output'];
  paymentInstructions?: Maybe<Scalars['String']['output']>;
  paymentMode: PaymentMode;
  stripeConnectAccount?: Maybe<StripeConnectAccount>;
};

export enum BusinessPaymentModeInput {
  NotConfigured = 'NOT_CONFIGURED',
  NoPayments = 'NO_PAYMENTS',
  OffPlatform = 'OFF_PLATFORM',
  Stripe = 'STRIPE'
}

export type BusinessUser = {
  __typename?: 'BusinessUser';
  accountSetupComplete: Scalars['Boolean']['output'];
  admin: Scalars['Boolean']['output'];
  business?: Maybe<Business>;
  defaultBusinessId?: Maybe<Scalars['ID']['output']>;
  email: Scalars['String']['output'];
  emailNotificationPrefs: EmailNotifications;
  emailSummaryPrefs: EmailSummaries;
  emailVerified: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

/** Autogenerated input type of CancelBookingOrderPayment */
export type CancelBookingOrderPaymentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  orderId: Scalars['ID']['input'];
};

/** Autogenerated input type of CancelEvent */
export type CancelEventInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  customerMessage?: InputMaybe<Scalars['String']['input']>;
  eventId: Scalars['ID']['input'];
};

/** Autogenerated return type of CancelEvent. */
export type CancelEventPayload = {
  __typename?: 'CancelEventPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
};

/** Autogenerated input type of CancelRecurringEvent */
export type CancelRecurringEventInput = {
  cancellationMode: RecurringEventCancellationModeInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  customerMessage?: InputMaybe<Scalars['String']['input']>;
  eventId: Scalars['ID']['input'];
  recurringEventInstance?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
};

/** Autogenerated return type of CancelRecurringEvent. */
export type CancelRecurringEventPayload = {
  __typename?: 'CancelRecurringEventPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
};

export type CheckoutSession = {
  __typename?: 'CheckoutSession';
  id: Scalars['ID']['output'];
  orderReference: Scalars['String']['output'];
  orderType: OrderType;
  paymentStatus: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export enum CheckoutUiMode {
  Browser = 'BROWSER',
  Iframe = 'IFRAME'
}

export type CheckoutUrlsInput = {
  cancel?: InputMaybe<Scalars['String']['input']>;
  return?: InputMaybe<Scalars['String']['input']>;
  success?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of CreateAddPaymentMethodUrl */
export type CreateAddPaymentMethodUrlInput = {
  cancelUrl: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  successUrl: Scalars['String']['input'];
};

/** Autogenerated input type of CreateBillingPortalUrl */
export type CreateBillingPortalUrlInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  returnUrl: Scalars['String']['input'];
};

/** Autogenerated return type of CreateBillingPortalUrl. */
export type CreateBillingPortalUrlPayload = {
  __typename?: 'CreateBillingPortalUrlPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

/** Autogenerated input type of CreateBookingOrder */
export type CreateBookingOrderInput = {
  checkoutUrls: CheckoutUrlsInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  events: Array<EventBookingInput>;
  trialPassRedemptionCode?: InputMaybe<Scalars['String']['input']>;
  uiMode?: InputMaybe<CheckoutUiMode>;
  userId: Scalars['ID']['input'];
};

/** Autogenerated input type of CreateBusiness */
export type CreateBusinessInput = {
  bookingCancellationPeriod?: InputMaybe<Scalars['Int']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  countryCode: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  facebook?: InputMaybe<Scalars['String']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phone?: InputMaybe<PhoneNumberInput>;
  tiktok?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
  whatsapp?: InputMaybe<Scalars['String']['input']>;
  youtube?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of CreateBusinessUser */
export type CreateBusinessUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  recaptchaToken: Scalars['String']['input'];
};

/** Autogenerated input type of CreateBusinessUserSession */
export type CreateBusinessUserSessionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

/** Autogenerated input type of CreateCreditBundle */
export type CreateCreditBundleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  creditValidityMonths: Scalars['Int']['input'];
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  price: Scalars['Int']['input'];
  totalCredits: Scalars['Int']['input'];
};

/** Autogenerated input type of CreateCreditOrder */
export type CreateCreditOrderInput = {
  checkoutUrls: CheckoutUrlsInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  creditBundle: CreditBundleInput;
};

/** Autogenerated input type of CreateEvent */
export type CreateEventInput = {
  bookingMessage?: InputMaybe<Scalars['String']['input']>;
  capacity?: InputMaybe<Scalars['Int']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  creditEligible?: InputMaybe<Scalars['Boolean']['input']>;
  description: Scalars['String']['input'];
  duration: Scalars['Int']['input'];
  images?: InputMaybe<Array<EventImageInput>>;
  location?: InputMaybe<EventLocationInput>;
  membershipEligible?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  price?: InputMaybe<Scalars['Int']['input']>;
  recurrence?: InputMaybe<EventRecurrenceInput>;
  startTime: Scalars['ISO8601DateTime']['input'];
  trialPassEligible?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated input type of CreateMembershipCheckoutUrl */
export type CreateMembershipCheckoutUrlInput = {
  checkoutUrls: CheckoutUrlsInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  membershipPlanId: Scalars['ID']['input'];
};

/** Autogenerated return type of CreateMembershipCheckoutUrl. */
export type CreateMembershipCheckoutUrlPayload = {
  __typename?: 'CreateMembershipCheckoutUrlPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

/** Autogenerated input type of CreateMembershipPlan */
export type CreateMembershipPlanInput = {
  bookingAllowance: Scalars['Int']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  price: Scalars['Int']['input'];
};

/** Autogenerated input type of CreateOrUpdateFirstBusiness */
export type CreateOrUpdateFirstBusinessInput = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of CreateOrUpdateTrialPass */
export type CreateOrUpdateTrialPassInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  enabled: Scalars['Boolean']['input'];
  redemptionCode: Scalars['String']['input'];
};

/** Autogenerated input type of CreateRecurringEventException */
export type CreateRecurringEventExceptionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  eventId: Scalars['ID']['input'];
  exceptionParams: EventUpdateInput;
  recurringEventInstance: Scalars['ISO8601DateTime']['input'];
};

/** Autogenerated input type of CreateStripeConnectOnboardingUrl */
export type CreateStripeConnectOnboardingUrlInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  refreshUrl: Scalars['String']['input'];
  returnUrl: Scalars['String']['input'];
};

/** Autogenerated return type of CreateStripeConnectOnboardingUrl. */
export type CreateStripeConnectOnboardingUrlPayload = {
  __typename?: 'CreateStripeConnectOnboardingUrlPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

/** Autogenerated input type of CreateUserWithoutLogin */
export type CreateUserWithoutLoginInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  marketingOptIn?: InputMaybe<Scalars['Boolean']['input']>;
  phone?: InputMaybe<PhoneNumberInput>;
};

export type Credit = {
  __typename?: 'Credit';
  expiry: Scalars['ISO8601DateTime']['output'];
  redeemed: Scalars['Boolean']['output'];
};

export type CreditBundle = {
  __typename?: 'CreditBundle';
  creditOrders: Array<CreditOrder>;
  creditValidityMonths: Scalars['Int']['output'];
  deleted?: Maybe<Scalars['Boolean']['output']>;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  price: Money;
  totalCredits: Scalars['Int']['output'];
  unredeemedCreditsCount: Scalars['Int']['output'];
};

export type CreditBundleInput = {
  creditValidityMonths: Scalars['Int']['input'];
  id: Scalars['ID']['input'];
  priceCents: Scalars['Int']['input'];
  totalCredits: Scalars['Int']['input'];
};

export type CreditBundleUpdateInput = {
  creditValidityMonths?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Int']['input']>;
  totalCredits?: InputMaybe<Scalars['Int']['input']>;
};

export type CreditOrder = {
  __typename?: 'CreditOrder';
  createdAt: Scalars['ISO8601DateTime']['output'];
  creditBundle: CreditBundle;
  credits: Array<Credit>;
  reference: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type CreditOrderResponse = {
  __typename?: 'CreditOrderResponse';
  creditOrder: CreditOrder;
  paymentRedirectUrl: Scalars['String']['output'];
};

/** A currency as defined by the ISO 4217 standard. */
export type Currency = {
  __typename?: 'Currency';
  /** The currency format as defined by IS0 4217. */
  isoCode: Iso4217;
};

/** Autogenerated input type of DeleteBusiness */
export type DeleteBusinessInput = {
  businessId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of DeleteBusiness. */
export type DeleteBusinessPayload = {
  __typename?: 'DeleteBusinessPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
};

/** Autogenerated input type of DeleteBusinessUser */
export type DeleteBusinessUserInput = {
  businessUserId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of DeleteBusinessUser. */
export type DeleteBusinessUserPayload = {
  __typename?: 'DeleteBusinessUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
};

/** Autogenerated input type of DeleteCreditBundle */
export type DeleteCreditBundleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  creditBundleId: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteCreditBundle. */
export type DeleteCreditBundlePayload = {
  __typename?: 'DeleteCreditBundlePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
};

/** Autogenerated input type of DeleteMembershipPlan */
export type DeleteMembershipPlanInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  membershipPlanId: Scalars['ID']['input'];
};

/** Autogenerated return type of DeleteMembershipPlan. */
export type DeleteMembershipPlanPayload = {
  __typename?: 'DeleteMembershipPlanPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
};

export type EmailNotifications = {
  __typename?: 'EmailNotifications';
  bookingCancellation?: Maybe<Scalars['Boolean']['output']>;
  creditPurchase?: Maybe<Scalars['Boolean']['output']>;
  everyBooking?: Maybe<Scalars['Boolean']['output']>;
  firstBooking?: Maybe<Scalars['Boolean']['output']>;
};

export type EmailNotificationsInput = {
  bookingCancellation: Scalars['Boolean']['input'];
  creditPurchase: Scalars['Boolean']['input'];
  everyBooking: Scalars['Boolean']['input'];
  firstBooking: Scalars['Boolean']['input'];
};

export type EmailSummaries = {
  __typename?: 'EmailSummaries';
  eventsToday?: Maybe<Scalars['Boolean']['output']>;
  eventsTomorrow?: Maybe<Scalars['Boolean']['output']>;
};

export type EmailSummariesInput = {
  eventsToday: Scalars['Boolean']['input'];
  eventsTomorrow: Scalars['Boolean']['input'];
};

/** Autogenerated input type of EndBusinessUserSession */
export type EndBusinessUserSessionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of EndBusinessUserSession. */
export type EndBusinessUserSessionPayload = {
  __typename?: 'EndBusinessUserSessionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
};

export type Event = {
  __typename?: 'Event';
  bookingMessage?: Maybe<Scalars['String']['output']>;
  bookingsCount: Scalars['Int']['output'];
  cancelled?: Maybe<Scalars['Boolean']['output']>;
  capacity?: Maybe<Scalars['Int']['output']>;
  creditEligible: Scalars['Boolean']['output'];
  description: Scalars['String']['output'];
  endTime: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  images: Array<EventImage>;
  locationName?: Maybe<Scalars['String']['output']>;
  locationUrl?: Maybe<Scalars['String']['output']>;
  membershipEligible: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  price: Money;
  recurrence?: Maybe<Scalars['JSON']['output']>;
  shareableId: ShareableId;
  startTime: Scalars['ISO8601DateTime']['output'];
  timeZone: Scalars['String']['output'];
  trialPassEligible: Scalars['Boolean']['output'];
  type: Scalars['String']['output'];
};

export type EventBookingInput = {
  eventId: Scalars['ID']['input'];
  quantity: Scalars['Int']['input'];
  startTime?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
};

export type EventImage = {
  __typename?: 'EventImage';
  id: Scalars['ID']['output'];
  index: Scalars['Int']['output'];
  key: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type EventImageInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  index: Scalars['Int']['input'];
  key?: InputMaybe<Scalars['ID']['input']>;
  url: Scalars['String']['input'];
};

export type EventLocationInput = {
  name: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export type EventRecurrenceInput = {
  recurrenceDays: Array<Scalars['Int']['input']>;
  recurrenceEndDate?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  recurrenceFrequency: Scalars['String']['input'];
};

export type EventUpdateInput = {
  bookingMessage?: InputMaybe<Scalars['String']['input']>;
  capacity?: InputMaybe<Scalars['Int']['input']>;
  creditEligible?: InputMaybe<Scalars['Boolean']['input']>;
  deletedImages?: InputMaybe<Array<EventImageInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  images?: InputMaybe<Array<EventImageInput>>;
  location?: InputMaybe<EventLocationInput>;
  membershipEligible?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  newImages?: InputMaybe<Array<EventImageInput>>;
  price?: InputMaybe<Scalars['Int']['input']>;
  startTime?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  trialPassEligible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type HtmlMetaTags = {
  __typename?: 'HtmlMetaTags';
  description: Scalars['String']['output'];
  image?: Maybe<Scalars['String']['output']>;
  imageHeight?: Maybe<Scalars['String']['output']>;
  imageWidth?: Maybe<Scalars['String']['output']>;
  locale: Scalars['String']['output'];
  themeColor: Scalars['String']['output'];
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

/** A valid ISO 4217 currency code string. */
export enum Iso4217 {
  /** United Arab Emirates Dirham */
  Aed = 'AED',
  /** Afghan Afghani */
  Afn = 'AFN',
  /** Albanian Lek */
  All = 'ALL',
  /** Armenian Dram */
  Amd = 'AMD',
  /** Netherlands Antillean Gulden */
  Ang = 'ANG',
  /** Angolan Kwanza */
  Aoa = 'AOA',
  /** Argentine Peso */
  Ars = 'ARS',
  /** Australian Dollar */
  Aud = 'AUD',
  /** Aruban Florin */
  Awg = 'AWG',
  /** Azerbaijani Manat */
  Azn = 'AZN',
  /** Bosnia and Herzegovina Convertible Mark */
  Bam = 'BAM',
  /** Barbadian Dollar */
  Bbd = 'BBD',
  /** Bitcoin Cash */
  Bch = 'BCH',
  /** Bangladeshi Taka */
  Bdt = 'BDT',
  /** Bulgarian Lev */
  Bgn = 'BGN',
  /** Bahraini Dinar */
  Bhd = 'BHD',
  /** Burundian Franc */
  Bif = 'BIF',
  /** Bermudian Dollar */
  Bmd = 'BMD',
  /** Brunei Dollar */
  Bnd = 'BND',
  /** Bolivian Boliviano */
  Bob = 'BOB',
  /** Brazilian Real */
  Brl = 'BRL',
  /** Bahamian Dollar */
  Bsd = 'BSD',
  /** Bitcoin */
  Btc = 'BTC',
  /** Bhutanese Ngultrum */
  Btn = 'BTN',
  /** Botswana Pula */
  Bwp = 'BWP',
  /** Belarusian Ruble */
  Byr = 'BYR',
  /** Belize Dollar */
  Bzd = 'BZD',
  /** Canadian Dollar */
  Cad = 'CAD',
  /** Congolese Franc */
  Cdf = 'CDF',
  /** Swiss Franc */
  Chf = 'CHF',
  /** Unidad de Fomento */
  Clf = 'CLF',
  /** Chilean Peso */
  Clp = 'CLP',
  /** Chinese Renminbi Yuan Offshore */
  Cnh = 'CNH',
  /** Chinese Renminbi Yuan */
  Cny = 'CNY',
  /** Colombian Peso */
  Cop = 'COP',
  /** Costa Rican Colón */
  Crc = 'CRC',
  /** Cuban Convertible Peso */
  Cuc = 'CUC',
  /** Cuban Peso */
  Cup = 'CUP',
  /** Cape Verdean Escudo */
  Cve = 'CVE',
  /** Czech Koruna */
  Czk = 'CZK',
  /** Djiboutian Franc */
  Djf = 'DJF',
  /** Danish Krone */
  Dkk = 'DKK',
  /** Dominican Peso */
  Dop = 'DOP',
  /** Algerian Dinar */
  Dzd = 'DZD',
  /** Estonian Kroon */
  Eek = 'EEK',
  /** Egyptian Pound */
  Egp = 'EGP',
  /** Eritrean Nakfa */
  Ern = 'ERN',
  /** Ethiopian Birr */
  Etb = 'ETB',
  /** Euro */
  Eur = 'EUR',
  /** Fijian Dollar */
  Fjd = 'FJD',
  /** Falkland Pound */
  Fkp = 'FKP',
  /** British Pound */
  Gbp = 'GBP',
  /** British Penny */
  Gbx = 'GBX',
  /** Georgian Lari */
  Gel = 'GEL',
  /** Guernsey Pound */
  Ggp = 'GGP',
  /** Ghanaian Cedi */
  Ghs = 'GHS',
  /** Gibraltar Pound */
  Gip = 'GIP',
  /** Gambian Dalasi */
  Gmd = 'GMD',
  /** Guinean Franc */
  Gnf = 'GNF',
  /** Guatemalan Quetzal */
  Gtq = 'GTQ',
  /** Guyanese Dollar */
  Gyd = 'GYD',
  /** Hong Kong Dollar */
  Hkd = 'HKD',
  /** Honduran Lempira */
  Hnl = 'HNL',
  /** Croatian Kuna */
  Hrk = 'HRK',
  /** Haitian Gourde */
  Htg = 'HTG',
  /** Hungarian Forint */
  Huf = 'HUF',
  /** Indonesian Rupiah */
  Idr = 'IDR',
  /** Israeli New Sheqel */
  Ils = 'ILS',
  /** Isle of Man Pound */
  Imp = 'IMP',
  /** Indian Rupee */
  Inr = 'INR',
  /** Iraqi Dinar */
  Iqd = 'IQD',
  /** Iranian Rial */
  Irr = 'IRR',
  /** Icelandic Króna */
  Isk = 'ISK',
  /** Jersey Pound */
  Jep = 'JEP',
  /** Jamaican Dollar */
  Jmd = 'JMD',
  /** Jordanian Dinar */
  Jod = 'JOD',
  /** Japanese Yen */
  Jpy = 'JPY',
  /** Kenyan Shilling */
  Kes = 'KES',
  /** Kyrgyzstani Som */
  Kgs = 'KGS',
  /** Cambodian Riel */
  Khr = 'KHR',
  /** Comorian Franc */
  Kmf = 'KMF',
  /** North Korean Won */
  Kpw = 'KPW',
  /** South Korean Won */
  Krw = 'KRW',
  /** Kuwaiti Dinar */
  Kwd = 'KWD',
  /** Cayman Islands Dollar */
  Kyd = 'KYD',
  /** Kazakhstani Tenge */
  Kzt = 'KZT',
  /** Lao Kip */
  Lak = 'LAK',
  /** Lebanese Pound */
  Lbp = 'LBP',
  /** Sri Lankan Rupee */
  Lkr = 'LKR',
  /** Liberian Dollar */
  Lrd = 'LRD',
  /** Lesotho Loti */
  Lsl = 'LSL',
  /** Lithuanian Litas */
  Ltl = 'LTL',
  /** Latvian Lats */
  Lvl = 'LVL',
  /** Libyan Dinar */
  Lyd = 'LYD',
  /** Moroccan Dirham */
  Mad = 'MAD',
  /** Moldovan Leu */
  Mdl = 'MDL',
  /** Malagasy Ariary */
  Mga = 'MGA',
  /** Macedonian Denar */
  Mkd = 'MKD',
  /** Myanmar Kyat */
  Mmk = 'MMK',
  /** Mongolian Tögrög */
  Mnt = 'MNT',
  /** Macanese Pataca */
  Mop = 'MOP',
  /** Mauritanian Ouguiya */
  Mru = 'MRU',
  /** Maltese Lira */
  Mtl = 'MTL',
  /** Mauritian Rupee */
  Mur = 'MUR',
  /** Maldivian Rufiyaa */
  Mvr = 'MVR',
  /** Malawian Kwacha */
  Mwk = 'MWK',
  /** Mexican Peso */
  Mxn = 'MXN',
  /** Malaysian Ringgit */
  Myr = 'MYR',
  /** Mozambican Metical */
  Mzn = 'MZN',
  /** Namibian Dollar */
  Nad = 'NAD',
  /** Nigerian Naira */
  Ngn = 'NGN',
  /** Nicaraguan Córdoba */
  Nio = 'NIO',
  /** Norwegian Krone */
  Nok = 'NOK',
  /** Nepalese Rupee */
  Npr = 'NPR',
  /** New Zealand Dollar */
  Nzd = 'NZD',
  /** Omani Rial */
  Omr = 'OMR',
  /** Panamanian Balboa */
  Pab = 'PAB',
  /** Peruvian Sol */
  Pen = 'PEN',
  /** Papua New Guinean Kina */
  Pgk = 'PGK',
  /** Philippine Peso */
  Php = 'PHP',
  /** Pakistani Rupee */
  Pkr = 'PKR',
  /** Polish Złoty */
  Pln = 'PLN',
  /** Paraguayan Guaraní */
  Pyg = 'PYG',
  /** Qatari Riyal */
  Qar = 'QAR',
  /** Romanian Leu */
  Ron = 'RON',
  /** Serbian Dinar */
  Rsd = 'RSD',
  /** Russian Ruble */
  Rub = 'RUB',
  /** Rwandan Franc */
  Rwf = 'RWF',
  /** Saudi Riyal */
  Sar = 'SAR',
  /** Solomon Islands Dollar */
  Sbd = 'SBD',
  /** Seychellois Rupee */
  Scr = 'SCR',
  /** Sudanese Pound */
  Sdg = 'SDG',
  /** Swedish Krona */
  Sek = 'SEK',
  /** Singapore Dollar */
  Sgd = 'SGD',
  /** Saint Helenian Pound */
  Shp = 'SHP',
  /** Slovak Koruna */
  Skk = 'SKK',
  /** New Leone */
  Sle = 'SLE',
  /** Sierra Leonean Leone */
  Sll = 'SLL',
  /** Somali Shilling */
  Sos = 'SOS',
  /** Surinamese Dollar */
  Srd = 'SRD',
  /** South Sudanese Pound */
  Ssp = 'SSP',
  /** São Tomé and Príncipe Dobra */
  Std = 'STD',
  /** São Tomé and Príncipe Second Dobra */
  Stn = 'STN',
  /** Salvadoran Colón */
  Svc = 'SVC',
  /** Syrian Pound */
  Syp = 'SYP',
  /** Swazi Lilangeni */
  Szl = 'SZL',
  /** Thai Baht */
  Thb = 'THB',
  /** Tajikistani Somoni */
  Tjs = 'TJS',
  /** Turkmenistani Manat */
  Tmt = 'TMT',
  /** Tunisian Dinar */
  Tnd = 'TND',
  /** Tongan Paʻanga */
  Top = 'TOP',
  /** Turkish Lira */
  Try = 'TRY',
  /** Trinidad and Tobago Dollar */
  Ttd = 'TTD',
  /** New Taiwan Dollar */
  Twd = 'TWD',
  /** Tanzanian Shilling */
  Tzs = 'TZS',
  /** Ukrainian Hryvnia */
  Uah = 'UAH',
  /** Ugandan Shilling */
  Ugx = 'UGX',
  /** United States Dollar */
  Usd = 'USD',
  /** USD Coin */
  Usdc = 'USDC',
  /** Uruguayan Peso */
  Uyu = 'UYU',
  /** Uzbekistan Som */
  Uzs = 'UZS',
  /** Venezuelan Bolívar */
  Vef = 'VEF',
  /** Venezuelan Bolívar Soberano */
  Ves = 'VES',
  /** Vietnamese Đồng */
  Vnd = 'VND',
  /** Vanuatu Vatu */
  Vuv = 'VUV',
  /** Samoan Tala */
  Wst = 'WST',
  /** Central African Cfa Franc */
  Xaf = 'XAF',
  /** Silver (Troy Ounce) */
  Xag = 'XAG',
  /** Gold (Troy Ounce) */
  Xau = 'XAU',
  /** European Composite Unit */
  Xba = 'XBA',
  /** European Monetary Unit */
  Xbb = 'XBB',
  /** European Unit of Account 9 */
  Xbc = 'XBC',
  /** European Unit of Account 17 */
  Xbd = 'XBD',
  /** East Caribbean Dollar */
  Xcd = 'XCD',
  /** Special Drawing Rights */
  Xdr = 'XDR',
  /** UIC Franc */
  Xfu = 'XFU',
  /** West African Cfa Franc */
  Xof = 'XOF',
  /** Palladium */
  Xpd = 'XPD',
  /** Cfp Franc */
  Xpf = 'XPF',
  /** Platinum */
  Xpt = 'XPT',
  /** Codes specifically reserved for testing purposes */
  Xts = 'XTS',
  /** Yemeni Rial */
  Yer = 'YER',
  /** South African Rand */
  Zar = 'ZAR',
  /** Zambian Kwacha */
  Zmk = 'ZMK',
  /** Zimbabwean Dollar */
  Zwd = 'ZWD'
}

export type Membership = {
  __typename?: 'Membership';
  active: Scalars['Boolean']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  currentPeriodBookingQuota: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  membershipPlanPrice: MembershipPlanPrice;
  name: Scalars['String']['output'];
  nextPeriodBookingQuota: Scalars['Int']['output'];
  paymentOverdue: Scalars['Boolean']['output'];
  stripeSubscription: StripeSubscription;
};

export type MembershipPlan = {
  __typename?: 'MembershipPlan';
  activeMemberships: Array<Membership>;
  bookingAllowance: Scalars['Int']['output'];
  defaultPriceId: Scalars['ID']['output'];
  deleted: Scalars['Boolean']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  membershipPlanPrices: Array<MembershipPlanPrice>;
  name: Scalars['String']['output'];
};

export type MembershipPlanPrice = {
  __typename?: 'MembershipPlanPrice';
  id: Scalars['ID']['output'];
  price: Money;
};

/** An object representing money, with an amount and currency. */
export type Money = {
  __typename?: 'Money';
  /** Numerical amount of the money. */
  amount: Scalars['Float']['output'];
  currency: Currency;
};

export type Mutation = {
  __typename?: 'Mutation';
  businessCancelBooking?: Maybe<BusinessCancelBookingPayload>;
  cancelBookingOrderPayment?: Maybe<StripeCheckoutSession>;
  cancelEvent?: Maybe<CancelEventPayload>;
  cancelRecurringEvent?: Maybe<CancelRecurringEventPayload>;
  createAddPaymentMethodUrl: StripeCheckoutSession;
  createBillingPortalUrl?: Maybe<CreateBillingPortalUrlPayload>;
  createBookingOrder?: Maybe<BookingOrderResponse>;
  createBusiness?: Maybe<Business>;
  createBusinessUser?: Maybe<BusinessUser>;
  createBusinessUserSession?: Maybe<BusinessUser>;
  createCreditBundle?: Maybe<CreditBundle>;
  createCreditOrder?: Maybe<CreditOrderResponse>;
  createEvent?: Maybe<Event>;
  createMembershipCheckoutUrl?: Maybe<CreateMembershipCheckoutUrlPayload>;
  createMembershipPlan?: Maybe<MembershipPlan>;
  createOrUpdateFirstBusiness?: Maybe<Business>;
  createOrUpdateTrialPass?: Maybe<TrialPass>;
  createRecurringEventException?: Maybe<Event>;
  createStripeConnectOnboardingUrl?: Maybe<CreateStripeConnectOnboardingUrlPayload>;
  createUserWithoutLogin?: Maybe<User>;
  deleteBusiness?: Maybe<DeleteBusinessPayload>;
  deleteBusinessUser?: Maybe<DeleteBusinessUserPayload>;
  deleteCreditBundle?: Maybe<DeleteCreditBundlePayload>;
  deleteMembershipPlan?: Maybe<DeleteMembershipPlanPayload>;
  endBusinessUserSession?: Maybe<EndBusinessUserSessionPayload>;
  newBusinessUserPassword?: Maybe<NewBusinessUserPasswordPayload>;
  resendBusinessUserEmailVerificationToken?: Maybe<ResendBusinessUserEmailVerificationTokenPayload>;
  resetBusinessUserPassword?: Maybe<ResetBusinessUserPasswordPayload>;
  signInApple?: Maybe<User>;
  signInGoogle?: Maybe<User>;
  signOut?: Maybe<SignOutPayload>;
  submitEnquiryMessage?: Maybe<SubmitEnquiryMessagePayload>;
  updateBusiness?: Maybe<UpdateBusinessPayload>;
  updateBusinessBillingCard: BusinessPaymentConfig;
  updateBusinessPaymentConfig: BusinessPaymentConfig;
  updateBusinessUserEmail?: Maybe<UpdateBusinessUserEmailPayload>;
  updateBusinessUserEmailPrefs?: Maybe<UpdateBusinessUserEmailPrefsPayload>;
  updateBusinessUserName?: Maybe<UpdateBusinessUserNamePayload>;
  updateBusinessUserPassword?: Maybe<UpdateBusinessUserPasswordPayload>;
  updateCreditBundle?: Maybe<CreditBundle>;
  updateEvent?: Maybe<Event>;
  updateUserWithLogin?: Maybe<User>;
  userCancelBooking?: Maybe<UserCancelBookingPayload>;
  validateTrialPassRedemptionCode?: Maybe<ValidateTrialPassRedemptionCodePayload>;
  verifyBusinessUserEmail?: Maybe<VerifyBusinessUserEmailPayload>;
};


export type MutationBusinessCancelBookingArgs = {
  input: BusinessCancelBookingInput;
};


export type MutationCancelBookingOrderPaymentArgs = {
  input: CancelBookingOrderPaymentInput;
};


export type MutationCancelEventArgs = {
  input: CancelEventInput;
};


export type MutationCancelRecurringEventArgs = {
  input: CancelRecurringEventInput;
};


export type MutationCreateAddPaymentMethodUrlArgs = {
  input: CreateAddPaymentMethodUrlInput;
};


export type MutationCreateBillingPortalUrlArgs = {
  input: CreateBillingPortalUrlInput;
};


export type MutationCreateBookingOrderArgs = {
  input: CreateBookingOrderInput;
};


export type MutationCreateBusinessArgs = {
  input: CreateBusinessInput;
};


export type MutationCreateBusinessUserArgs = {
  input: CreateBusinessUserInput;
};


export type MutationCreateBusinessUserSessionArgs = {
  input: CreateBusinessUserSessionInput;
};


export type MutationCreateCreditBundleArgs = {
  input: CreateCreditBundleInput;
};


export type MutationCreateCreditOrderArgs = {
  input: CreateCreditOrderInput;
};


export type MutationCreateEventArgs = {
  input: CreateEventInput;
};


export type MutationCreateMembershipCheckoutUrlArgs = {
  input: CreateMembershipCheckoutUrlInput;
};


export type MutationCreateMembershipPlanArgs = {
  input: CreateMembershipPlanInput;
};


export type MutationCreateOrUpdateFirstBusinessArgs = {
  input: CreateOrUpdateFirstBusinessInput;
};


export type MutationCreateOrUpdateTrialPassArgs = {
  input: CreateOrUpdateTrialPassInput;
};


export type MutationCreateRecurringEventExceptionArgs = {
  input: CreateRecurringEventExceptionInput;
};


export type MutationCreateStripeConnectOnboardingUrlArgs = {
  input: CreateStripeConnectOnboardingUrlInput;
};


export type MutationCreateUserWithoutLoginArgs = {
  input: CreateUserWithoutLoginInput;
};


export type MutationDeleteBusinessArgs = {
  input: DeleteBusinessInput;
};


export type MutationDeleteBusinessUserArgs = {
  input: DeleteBusinessUserInput;
};


export type MutationDeleteCreditBundleArgs = {
  input: DeleteCreditBundleInput;
};


export type MutationDeleteMembershipPlanArgs = {
  input: DeleteMembershipPlanInput;
};


export type MutationEndBusinessUserSessionArgs = {
  input: EndBusinessUserSessionInput;
};


export type MutationNewBusinessUserPasswordArgs = {
  input: NewBusinessUserPasswordInput;
};


export type MutationResendBusinessUserEmailVerificationTokenArgs = {
  input: ResendBusinessUserEmailVerificationTokenInput;
};


export type MutationResetBusinessUserPasswordArgs = {
  input: ResetBusinessUserPasswordInput;
};


export type MutationSignInAppleArgs = {
  input: SignInAppleInput;
};


export type MutationSignInGoogleArgs = {
  input: SignInGoogleInput;
};


export type MutationSignOutArgs = {
  input: SignOutInput;
};


export type MutationSubmitEnquiryMessageArgs = {
  input: SubmitEnquiryMessageInput;
};


export type MutationUpdateBusinessArgs = {
  input: UpdateBusinessInput;
};


export type MutationUpdateBusinessBillingCardArgs = {
  input: UpdateBusinessBillingCardInput;
};


export type MutationUpdateBusinessPaymentConfigArgs = {
  input: UpdateBusinessPaymentConfigInput;
};


export type MutationUpdateBusinessUserEmailArgs = {
  input: UpdateBusinessUserEmailInput;
};


export type MutationUpdateBusinessUserEmailPrefsArgs = {
  input: UpdateBusinessUserEmailPrefsInput;
};


export type MutationUpdateBusinessUserNameArgs = {
  input: UpdateBusinessUserNameInput;
};


export type MutationUpdateBusinessUserPasswordArgs = {
  input: UpdateBusinessUserPasswordInput;
};


export type MutationUpdateCreditBundleArgs = {
  input: UpdateCreditBundleInput;
};


export type MutationUpdateEventArgs = {
  input: UpdateEventInput;
};


export type MutationUpdateUserWithLoginArgs = {
  input: UpdateUserWithLoginInput;
};


export type MutationUserCancelBookingArgs = {
  input: UserCancelBookingInput;
};


export type MutationValidateTrialPassRedemptionCodeArgs = {
  input: ValidateTrialPassRedemptionCodeInput;
};


export type MutationVerifyBusinessUserEmailArgs = {
  input: VerifyBusinessUserEmailInput;
};

export type NewBusinessUserEmail = {
  __typename?: 'NewBusinessUserEmail';
  recaptcha: Scalars['Boolean']['output'];
  valid: Scalars['Boolean']['output'];
};

/** Autogenerated input type of NewBusinessUserPassword */
export type NewBusinessUserPasswordInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  resetToken: Scalars['String']['input'];
};

/** Autogenerated return type of NewBusinessUserPassword. */
export type NewBusinessUserPasswordPayload = {
  __typename?: 'NewBusinessUserPasswordPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
};

/** An object with an ID. */
export type Node = {
  /** ID of the object. */
  id: Scalars['ID']['output'];
};

export enum OrderType {
  BookingOrder = 'BOOKING_ORDER',
  CreditOrder = 'CREDIT_ORDER'
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type Payment = {
  __typename?: 'Payment';
  amount: Money;
  createdAt: Scalars['String']['output'];
  transactionId: Scalars['String']['output'];
};

export type PaymentCard = {
  __typename?: 'PaymentCard';
  brand: Scalars['String']['output'];
  expiryMonth: Scalars['String']['output'];
  expiryYear: Scalars['String']['output'];
  last4: Scalars['String']['output'];
};

export enum PaymentMode {
  NotConfigured = 'NOT_CONFIGURED',
  NoPayments = 'NO_PAYMENTS',
  OffPlatform = 'OFF_PLATFORM',
  Stripe = 'STRIPE'
}

export type PhoneNumberInput = {
  countryCode: Scalars['String']['input'];
  number?: InputMaybe<Scalars['String']['input']>;
};

export type Query = {
  __typename?: 'Query';
  accountSetupStatus: AccountSetupStatus;
  businessLogoPresignedPost: AwsPresignedPost;
  businessNotifications: Array<Scalars['String']['output']>;
  businessPaymentConfig: BusinessPaymentConfig;
  businesses: Array<Business>;
  checkoutSessionStatus: CheckoutSession;
  creditBundle: CreditBundle;
  creditBundles: Array<CreditBundle>;
  currentBusiness: Business;
  currentBusinessUser?: Maybe<BusinessUser>;
  currentUser: User;
  event: Event;
  eventBookings: Array<Booking>;
  eventImagePresignedPost: AwsPresignedPost;
  events: Array<Event>;
  htmlMetaTagsForPath: HtmlMetaTags;
  membershipPlan: MembershipPlan;
  membershipPlans: Array<MembershipPlan>;
  newBusinessUserEmail: NewBusinessUserEmail;
  /** Fetches an object given its ID. */
  node?: Maybe<Node>;
  /** Fetches a list of objects given a list of IDs. */
  nodes: Array<Maybe<Node>>;
  schedule: Array<Event>;
  searchUsers: Array<User>;
  sharedEvent?: Maybe<Event>;
  trialPass?: Maybe<TrialPass>;
  userBookings: Array<UserBooking>;
  userSummary: UserSummary;
  users: UserConnection;
};


export type QueryCheckoutSessionStatusArgs = {
  checkoutSessionId: Scalars['String']['input'];
};


export type QueryCreditBundleArgs = {
  creditBundleId: Scalars['ID']['input'];
};


export type QueryEventArgs = {
  eventId: Scalars['ID']['input'];
  instance?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
};


export type QueryEventBookingsArgs = {
  eventId: Scalars['ID']['input'];
  instance?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
};


export type QueryEventsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  startTime: Scalars['ISO8601DateTime']['input'];
};


export type QueryHtmlMetaTagsForPathArgs = {
  path: Scalars['String']['input'];
};


export type QueryMembershipPlanArgs = {
  membershipPlanId: Scalars['ID']['input'];
};


export type QueryNewBusinessUserEmailArgs = {
  email: Scalars['String']['input'];
  recaptcha: Scalars['String']['input'];
};


export type QueryNodeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryNodesArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type QuerySearchUsersArgs = {
  query: Scalars['String']['input'];
};


export type QuerySharedEventArgs = {
  shareableId: Scalars['String']['input'];
};


export type QueryUserBookingsArgs = {
  orderId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type QueryUserSummaryArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UsersFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export enum RecurringEventCancellationModeInput {
  CancelAll = 'CANCEL_ALL',
  CancelAllFromInstance = 'CANCEL_ALL_FROM_INSTANCE',
  CancelInstance = 'CANCEL_INSTANCE'
}

/** Autogenerated input type of ResendBusinessUserEmailVerificationToken */
export type ResendBusinessUserEmailVerificationTokenInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ResendBusinessUserEmailVerificationToken. */
export type ResendBusinessUserEmailVerificationTokenPayload = {
  __typename?: 'ResendBusinessUserEmailVerificationTokenPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
};

/** Autogenerated input type of ResetBusinessUserPassword */
export type ResetBusinessUserPasswordInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
};

/** Autogenerated return type of ResetBusinessUserPassword. */
export type ResetBusinessUserPasswordPayload = {
  __typename?: 'ResetBusinessUserPasswordPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
};

export type ShareableId = {
  __typename?: 'ShareableId';
  shareableId: Scalars['String']['output'];
};

/** Autogenerated input type of SignInApple */
export type SignInAppleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  idToken: Scalars['String']['input'];
};

/** Autogenerated input type of SignInGoogle */
export type SignInGoogleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  idToken: Scalars['String']['input'];
};

/** Autogenerated input type of SignOut */
export type SignOutInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of SignOut. */
export type SignOutPayload = {
  __typename?: 'SignOutPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
};

export type StripeCheckoutSession = {
  __typename?: 'StripeCheckoutSession';
  amountTotal: Scalars['Int']['output'];
  cancelUrl: Scalars['String']['output'];
  clientReferenceId: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  mode: Scalars['String']['output'];
  redirectUrl: Scalars['String']['output'];
  status: Scalars['String']['output'];
  successUrl: Scalars['String']['output'];
};

export type StripeConnectAccount = {
  __typename?: 'StripeConnectAccount';
  detailsSubmitted: Scalars['Boolean']['output'];
  email?: Maybe<Scalars['String']['output']>;
  readyForPayments: Scalars['Boolean']['output'];
  stripeAccountId: Scalars['String']['output'];
};

export type StripeSubscription = {
  __typename?: 'StripeSubscription';
  cancelAtPeriodEnd: Scalars['Boolean']['output'];
  currentPeriodEnd: Scalars['ISO8601DateTime']['output'];
  currentPeriodStart: Scalars['ISO8601DateTime']['output'];
  status: Scalars['String']['output'];
};

/** Autogenerated input type of SubmitEnquiryMessage */
export type SubmitEnquiryMessageInput = {
  businessId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  message: Scalars['String']['input'];
};

/** Autogenerated return type of SubmitEnquiryMessage. */
export type SubmitEnquiryMessagePayload = {
  __typename?: 'SubmitEnquiryMessagePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
};

export type TrialPass = {
  __typename?: 'TrialPass';
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  redemptionCode: Scalars['String']['output'];
};

export type TrialPassRedemption = {
  __typename?: 'TrialPassRedemption';
  createdAt: Scalars['ISO8601DateTime']['output'];
};

/** Autogenerated input type of UpdateBusinessBillingCard */
export type UpdateBusinessBillingCardInput = {
  checkoutSessionId: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of UpdateBusiness */
export type UpdateBusinessInput = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  bookingCancellationPeriod?: InputMaybe<Scalars['Int']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  facebook?: InputMaybe<Scalars['String']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  logoUpdated?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<PhoneNumberInput>;
  tiktok?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
  whatsapp?: InputMaybe<Scalars['String']['input']>;
  youtube?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UpdateBusiness. */
export type UpdateBusinessPayload = {
  __typename?: 'UpdateBusinessPayload';
  business?: Maybe<Business>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of UpdateBusinessPaymentConfig */
export type UpdateBusinessPaymentConfigInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  paymentInstructions?: InputMaybe<Scalars['String']['input']>;
  paymentMode?: InputMaybe<BusinessPaymentModeInput>;
};

/** Autogenerated input type of UpdateBusinessUserEmail */
export type UpdateBusinessUserEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
};

/** Autogenerated return type of UpdateBusinessUserEmail. */
export type UpdateBusinessUserEmailPayload = {
  __typename?: 'UpdateBusinessUserEmailPayload';
  businessUser?: Maybe<BusinessUser>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of UpdateBusinessUserEmailPrefs */
export type UpdateBusinessUserEmailPrefsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  emailNotificationPrefs?: InputMaybe<EmailNotificationsInput>;
  emailSummaryPrefs?: InputMaybe<EmailSummariesInput>;
};

/** Autogenerated return type of UpdateBusinessUserEmailPrefs. */
export type UpdateBusinessUserEmailPrefsPayload = {
  __typename?: 'UpdateBusinessUserEmailPrefsPayload';
  businessUser?: Maybe<BusinessUser>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of UpdateBusinessUserName */
export type UpdateBusinessUserNameInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/** Autogenerated return type of UpdateBusinessUserName. */
export type UpdateBusinessUserNamePayload = {
  __typename?: 'UpdateBusinessUserNamePayload';
  businessUser?: Maybe<BusinessUser>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of UpdateBusinessUserPassword */
export type UpdateBusinessUserPasswordInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  newPassword: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

/** Autogenerated return type of UpdateBusinessUserPassword. */
export type UpdateBusinessUserPasswordPayload = {
  __typename?: 'UpdateBusinessUserPasswordPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
};

/** Autogenerated input type of UpdateCreditBundle */
export type UpdateCreditBundleInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  creditBundleId: Scalars['ID']['input'];
  updateParams: CreditBundleUpdateInput;
};

/** Autogenerated input type of UpdateEvent */
export type UpdateEventInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  eventId: Scalars['ID']['input'];
  updateParams: EventUpdateInput;
};

/** Autogenerated input type of UpdateUserWithLogin */
export type UpdateUserWithLoginInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  marketingOptIn?: InputMaybe<Scalars['Boolean']['input']>;
  phone?: InputMaybe<PhoneNumberInput>;
};

export type User = {
  __typename?: 'User';
  availableCredits: Array<Credit>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  marketingOptIn: Scalars['Boolean']['output'];
  membership?: Maybe<Membership>;
  memberships: Array<Membership>;
  phone?: Maybe<Scalars['String']['output']>;
  phoneCountryCode?: Maybe<Scalars['String']['output']>;
  trialPassEligible: Scalars['Boolean']['output'];
  userLogin?: Maybe<UserLogin>;
};

export type UserBooking = {
  __typename?: 'UserBooking';
  cancellationPeriod: Scalars['Int']['output'];
  cancelled: Scalars['Boolean']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  event: BookedEvent;
  id: Scalars['ID']['output'];
  pricePaid: Money;
  quantity: Scalars['Int']['output'];
  recurringEventInstance?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

/** Autogenerated input type of UserCancelBooking */
export type UserCancelBookingInput = {
  bookingId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
};

/** Autogenerated return type of UserCancelBooking. */
export type UserCancelBookingPayload = {
  __typename?: 'UserCancelBookingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
};

/** The connection type for User. */
export type UserConnection = {
  __typename?: 'UserConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<User>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type UserEdge = {
  __typename?: 'UserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<User>;
};

export type UserLogin = {
  __typename?: 'UserLogin';
  provider: Scalars['String']['output'];
};

export type UserSummary = {
  __typename?: 'UserSummary';
  bookings: Array<Booking>;
  creditOrders: Array<CreditOrder>;
  membership?: Maybe<Membership>;
  trialPassRedemption?: Maybe<TrialPassRedemption>;
  user: User;
};

export enum UsersFilterInput {
  Credits = 'CREDITS',
  Memberships = 'MEMBERSHIPS'
}

/** Autogenerated input type of ValidateTrialPassRedemptionCode */
export type ValidateTrialPassRedemptionCodeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  redemptionCode: Scalars['String']['input'];
};

/** Autogenerated return type of ValidateTrialPassRedemptionCode. */
export type ValidateTrialPassRedemptionCodePayload = {
  __typename?: 'ValidateTrialPassRedemptionCodePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  valid: Scalars['Boolean']['output'];
};

/** Autogenerated input type of VerifyBusinessUserEmail */
export type VerifyBusinessUserEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};

/** Autogenerated return type of VerifyBusinessUserEmail. */
export type VerifyBusinessUserEmailPayload = {
  __typename?: 'VerifyBusinessUserEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
};

import { gql } from '@apollo/client'

export const fragments = gql`
  fragment BookingOrderParams on BookingOrder {
    id
    status
    reference
    user {
      ...UserParams
    }
    payment {
      transactionId
      createdAt
      amount {
        ...MoneyParams
      }
    }
  }

  fragment BusinessParams on Business {
    id
    accountId
    timeZone
    countryCode
    currencyCode
    name
    email
    phoneCountryCode
    phone
    logo
    color
    bookingCancellationPeriod
    bookingOptions {
      trialPassEnabled
      creditBundles {
        ...CreditBundleParams
      }
      membershipPlans {
        ...MembershipPlanParams
      }
    }
  }

  fragment CreditBundleParams on CreditBundle {
    id
    name
    description
    price {
      ...MoneyParams
    }
    totalCredits
    creditValidityMonths
  }

  fragment MembershipPlanParams on MembershipPlan {
    id
    name
    description
    membershipPlanPrices {
      price {
        ...MoneyParams
      }
    }
    bookingAllowance
  }

  fragment EventParams on Event {
    id
    type
    timeZone
    startTime
    endTime
    capacity
    locationName
    locationUrl
    name
    description
    creditEligible
    membershipEligible
    trialPassEligible
    bookingsCount
    cancelled
    bookingMessage
    price {
      ...MoneyParams
    }
    images {
      ...EventImageParams
    }
    recurrence
    shareableId {
      shareableId
    }
  }

  fragment EventImageParams on EventImage {
    id
    key
    index
    url
  }

  fragment UserParams on User {
    id
    firstName
    lastName
    email
    phoneCountryCode
    phone
    marketingOptIn
    availableCredits {
      expiry
    }
    membership {
      name
      membershipPlanPrice {
        price {
          ...MoneyParams
        }
      }
      currentPeriodBookingQuota
      nextPeriodBookingQuota
      stripeSubscription {
        currentPeriodStart
        currentPeriodEnd
      }
    }
    trialPassEligible
  }

  fragment MoneyParams on Money {
    amount
    currency {
      isoCode
    }
  }
`

import { useQuery } from '@apollo/client'
import { useState, useEffect, useContext, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { generateEventPath } from 'Routes'
import { useBusiness } from 'business/use-business'
import { CartContext } from 'context/CartProvider'
import { Business, Event } from 'generated/graphql'
import { SCHEDULE_QUERY } from 'graphql/Events'
import useWindowDimensions from 'hooks/useWindowDimensions'
import {
  getDaysForWeekCommencing,
  formatDate,
  datesAreSameDay,
  getOrdinal,
  getFirstDayOfThisWeek,
  getFirstDayOfNextWeek,
  getFirstDayOfThisMonth,
  getFirstDayOfNextMonth,
  getPreviousMonday,
  dateIsInRange,
  daysInMonth,
  addDaysToDate,
} from 'lib/dateUtils'
import { useLogin } from 'login/use-login'
import Basket from 'shared/components/Basket'
import BusinessLogoImage from 'shared/components/BusinessLogoImage'
import CookieNotice from 'shared/components/CookieNotice'
import PageLayout from 'shared/components/PageLayout'
import Spinner from 'shared/components/Spinner'
import UserProfileButton from 'shared/components/UserProfileButton'
import DateFilter from './DateFilter'
import { DateFilterType } from './DateFilterType'
import DateStrip from './DateStrip'
import DateStripNav from './DateStripNav'
import EventTile from './EventTile'

const EVENTS_TO_SHOW_ON_LOAD = 20

const ScheduleError = () => {
  const { t } = useTranslation()
  return (
    <div className="h-screen">
      <div className="flex h-full flex-col justify-center">
        <div className="flex flex-col items-center">
          <div className="text-lg">{t('error.generic')}</div>
          <div className="mt-4">
            <button
              onClick={() => window.location.reload()}
              className="button-primary"
            >
              {t('retry')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export interface ScheduleLocationState {
  event: Event
  eventFilterState: EventFilterState
}

interface EventFilterState {
  dateFilter: DateFilterType
  date: Date
  week: Array<Date>
}

const Schedule = () => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { isMobile } = useWindowDimensions()
  const locationState = location.state as EventFilterState
  const { cart } = useContext(CartContext)
  const { cartItems } = cart
  const cartItemCount = cartItems.length
  const today = new Date()
  const thisWeek = getDaysForWeekCommencing(today)
  const [date, setDate] = useState(locationState?.date || today)
  const [week, setWeek] = useState(locationState?.week || thisWeek)
  const [dateFilter, setDateFilter] = useState(
    locationState?.dateFilter || DateFilterType.None
  )
  const [showAllEvents, shouldShowAllEvents] = useState(false)
  const [ready, setReady] = useState(false)
  const { user } = useLogin()
  const { error: scheduleError, data: scheduleResponse } = useQuery(
    SCHEDULE_QUERY,
    {
      fetchPolicy: 'cache-first',
    }
  )
  const { business } = useBusiness()
  const scheduleEvents = scheduleResponse?.schedule

  useEffect(() => {
    // Clear location state because previous date/filter selections are now stale
    window.history.replaceState({}, document.title)
  }, [])

  useEffect(() => {
    if (business) {
      const firstDayOfThisWeek = getFirstDayOfThisWeek()
      const firstDayOfNextWeek = getFirstDayOfNextWeek()
      const firstDayOfThisMonth = getFirstDayOfThisMonth()
      const firstDayOfNextMonth = getFirstDayOfNextMonth()

      switch (dateFilter) {
        case DateFilterType.ThisWeek:
          setDate(firstDayOfThisWeek)
          setWeek(getDaysForWeekCommencing(firstDayOfThisWeek))
          break
        case DateFilterType.NextWeek:
          setDate(firstDayOfNextWeek)
          setWeek(getDaysForWeekCommencing(firstDayOfNextWeek))
          break
        case DateFilterType.ThisMonth:
          if (firstDayOfThisMonth.getTime() < firstDayOfThisWeek.getTime()) {
            setDate(firstDayOfThisWeek)
            setWeek(getDaysForWeekCommencing(firstDayOfThisWeek))
          } else {
            setDate(firstDayOfThisMonth)
            setWeek(getDaysForWeekCommencing(firstDayOfThisMonth))
          }
          break
        case DateFilterType.NextMonth:
          setDate(firstDayOfNextMonth)
          setWeek(
            getDaysForWeekCommencing(getPreviousMonday(firstDayOfNextMonth))
          )
          break
        default:
          break
      }
    }
  }, [dateFilter, business])

  useEffect(() => {
    if (scheduleEvents) {
      shouldShowAllEvents(true)
      setReady(true)
    }
  }, [scheduleEvents, setReady])

  const getDateRangeText = () => {
    if (showAllEvents) {
      return t('schedule')
    } else {
      if (dateFilter === DateFilterType.None) {
        if (datesAreSameDay(date, today)) {
          return t('calendar.selectedDate', { range: t('calendar.today') })
        } else {
          const text =
            date.toLocaleDateString(i18n.resolvedLanguage, {
              weekday: 'short',
            }) +
            ' ' +
            (i18n.resolvedLanguage === 'en-GB'
              ? getOrdinal(date.getDate())
              : date.getDate()) +
            ' ' +
            date.toLocaleDateString(i18n.resolvedLanguage, { month: 'long' })
          return t('calendar.selectedDate', { range: text })
        }
      } else {
        return t('calendar.selectedDate', {
          range: t(`calendar.dateFilter.${dateFilter}`),
        })
      }
    }
  }

  const showPreviousWeek = () => {
    const newFirstDayOfWeek = addDaysToDate(week[0], -7)
    const selectedDate =
      newFirstDayOfWeek.getTime() < today.getTime() ? today : newFirstDayOfWeek
    shouldShowAllEvents(false)
    setDateFilter(DateFilterType.None)
    setDate(selectedDate)
    setWeek(getDaysForWeekCommencing(newFirstDayOfWeek))
  }

  const showNextWeek = () => {
    const newFirstDayOfWeek = addDaysToDate(week[0], 7)
    shouldShowAllEvents(false)
    setDateFilter(DateFilterType.None)
    setDate(newFirstDayOfWeek)
    setWeek(getDaysForWeekCommencing(newFirstDayOfWeek))
  }

  const onDateSelected = (selectedDate: Date) => {
    shouldShowAllEvents(false)

    if (dateFilter !== DateFilterType.None) {
      setDateFilter(DateFilterType.None)
    }
    if (!datesAreSameDay(selectedDate, date)) {
      setDate(selectedDate)
    }
  }

  const showEventDetails = (event: Event) => {
    navigate(generateEventPath(event), {
      replace: false,
      state: {
        event,
        eventFilterState: {
          dateFilter,
          date,
          week,
        },
      },
    })
  }

  const dateFilterSelected = (type: DateFilterType) => {
    shouldShowAllEvents(false)
    setDateFilter(type)
  }

  const getEvents = () => {
    let events = null
    let rangeText

    if (date) {
      switch (dateFilter) {
        case DateFilterType.ThisWeek:
        case DateFilterType.NextWeek:
          rangeText = t(`calendar.dateFilter.${dateFilter}`)
          events = getEventsForWeekCommencing(date)
          break
        case DateFilterType.ThisMonth:
        case DateFilterType.NextMonth:
          rangeText = t(`calendar.dateFilter.${dateFilter}`)
          events = getEventsForMonthCommencing(date)
          break
        case DateFilterType.None:
          if (showAllEvents) {
            events = getAllEvents()
          } else {
            rangeText = datesAreSameDay(date, today)
              ? t('calendar.today')
              : formatDate({
                  date,
                  format: 'day',
                  timeZone: null,
                  locale: i18n.resolvedLanguage,
                })
            events = getEventsForCurrentDate()
            break
          }
      }
    }

    if (!events || events.length > 0) {
      return events
    } else {
      return (
        <div className="p-4 text-center text-sm text-gray-500">
          {dateFilter === DateFilterType.None
            ? t('calendar.noEvents')
            : t('calendar.noEventsForRange', { range: rangeText })}
        </div>
      )
    }
  }

  const createEventTile = (event: Event, key: number) => {
    return (
      <EventTile
        key={key}
        event={event}
        user={user}
        selected={showEventDetails}
      />
    )
  }

  const getEventsForWeekCommencing = (date: Date) => {
    const lastDay = addDaysToDate(date, 6)
    return scheduleEvents
      .reduce((filtered: Array<ReactNode>, event: Event) => {
        const eventStartTime = new Date(event.startTime)
        if (dateIsInRange(eventStartTime, date, lastDay)) {
          filtered.push(createEventTile(event, filtered.length))
        }
        return filtered
      }, [])
      .sort(function (a: Event, b: Event) {
        return Date.parse(a.startTime) - Date.parse(b.startTime)
      })
  }

  const getEventsForMonthCommencing = (date: Date) => {
    const lastDay = addDaysToDate(date, daysInMonth(date) - date.getDate())
    return scheduleEvents
      .reduce((filtered: Array<ReactNode>, event: Event) => {
        const eventStartTime = new Date(event.startTime)
        if (dateIsInRange(eventStartTime, date, lastDay)) {
          filtered.push(createEventTile(event, filtered.length))
        }
        return filtered
      }, [])
      .sort(function (a: Event, b: Event) {
        return Date.parse(a.startTime) - Date.parse(b.startTime)
      })
  }

  const getEventsForCurrentDate = () => {
    return scheduleEvents
      .reduce((filtered: Array<ReactNode>, event: Event) => {
        const eventStartTime = new Date(event.startTime)
        if (datesAreSameDay(eventStartTime, date)) {
          filtered.push(createEventTile(event, filtered.length))
        }
        return filtered
      }, [])
      .sort(function (a: Event, b: Event) {
        return Date.parse(a.startTime) - Date.parse(b.startTime)
      })
  }

  const getAllEvents = () => {
    return scheduleEvents
      .slice()
      .sort(function (a: Event, b: Event) {
        return Date.parse(a.startTime) - Date.parse(b.startTime)
      })
      .map((event: Event, index: number) => {
        return index < EVENTS_TO_SHOW_ON_LOAD
          ? createEventTile(event, index)
          : null
      })
  }

  if (business && scheduleError) {
    return <ScheduleError />
  }

  return (
    <PageLayout
      headerProps={{
        centerContent:
          ready && scheduleEvents ? (
            <div
              className="flex w-full max-w-3xl justify-between gap-3"
              data-testid="desktop-event-filter"
            >
              <div className="w-96">
                <DateStrip
                  week={week}
                  locale={i18n.resolvedLanguage}
                  selectedDate={date}
                  active={!showAllEvents && dateFilter === DateFilterType.None}
                  showNavArrows={!isMobile}
                  onDateSelected={onDateSelected}
                  onPrevSelected={showPreviousWeek}
                  onNextSelected={showNextWeek}
                />
              </div>
              <div className="flex items-center">
                <DateFilter
                  filterSelected={dateFilterSelected}
                  currentFilter={dateFilter}
                />
              </div>
            </div>
          ) : (
            <div></div>
          ),

        showBasketIcon: true,
      }}
    >
      {/* Mobile Header */}
      <div className="mx-auto my-auto flex h-auto w-full flex-col md:hidden md:max-w-sm md:py-6">
        <div className="my-2 mb-5 flex items-center justify-center">
          <div className="w-12">
            <UserProfileButton />
          </div>
          <div className="flex flex-grow">
            <div className="mx-auto">
              <BusinessLogoImage business={business as Business} />
            </div>
          </div>
          <div className="w-12"></div>
        </div>

        {ready && scheduleEvents && (
          <div>
            <div className="mb-2 w-full">
              <DateFilter
                filterSelected={dateFilterSelected}
                currentFilter={dateFilter}
              />
            </div>

            <DateStrip
              week={week}
              locale={i18n.resolvedLanguage}
              selectedDate={date}
              active={!showAllEvents && dateFilter === DateFilterType.None}
              showNavArrows={!isMobile}
              onDateSelected={onDateSelected}
              onPrevSelected={showPreviousWeek}
              onNextSelected={showNextWeek}
            />

            <DateStripNav
              week={week}
              text={getDateRangeText()}
              onPrevSelected={showPreviousWeek}
              onNextSelected={showNextWeek}
            />
          </div>
        )}
      </div>
      <div>
        <div className="md:mx-8">
          <div
            data-testid="scheduled-events"
            className={`${
              cartItemCount === 0 ? '' : 'last:mb-28'
            } md:mx-auto md:mt-5 md:max-w-3xl md:last:mb-0`}
          >
            {!ready && !scheduleEvents && (
              <div className="h-screen text-primary">
                <Spinner visible={!ready} text={t('loading')} />
              </div>
            )}

            {ready && scheduleEvents && getEvents()}
          </div>
        </div>
      </div>

      <Basket />
      <CookieNotice />
    </PageLayout>
  )
}

export default Schedule

import { gql } from '@apollo/client'

// Queries
export const SCHEDULE_QUERY = gql`
  query Schedule {
    schedule {
      ...EventParams
    }
  }
`

export const SHARED_EVENT_QUERY = gql`
  query SharedEvent($shareableId: String!) {
    sharedEvent(shareableId: $shareableId) {
      ...EventParams
    }
  }
`

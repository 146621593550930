import { useMutation, ApolloError } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import {
  LOGIN_PATH,
  SCHEDULE_PATH,
  CREDITS_CHECKOUT_PATH,
  CREDITS_CHECKOUT_SUCCESS_PATH,
  CREDITS_CHECKOUT_CANCEL_PATH,
} from 'Routes'
import { useBusiness } from 'business/use-business'
import { CreditBundle, CreditOrderResponse } from 'generated/graphql'
import { CREATE_CREDIT_ORDER_MUTATION } from 'graphql/CreditOrder'
import {
  ERROR_CREDIT_BUNDLE_DISCONTINUED,
  ERROR_PAYMENT_UNAVAILABLE,
} from 'graphql/errors'
import { formatMoney } from 'lib/moneyUtils'
import { useLogin } from 'login/use-login'
import CloseIcon from 'shared/components/CloseIcon'
import ErrorMessage from 'shared/components/ErrorMessage'
import OverlaySpinner from 'shared/components/OverlaySpinner'
import PageLayout from 'shared/components/PageLayout'
import UserProfileButton from 'shared/components/UserProfileButton'
import { creditBundleInputFromCreditBundle } from 'shared/helpers/graphql'

const CreditsView = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { business } = useBusiness()
  const { user } = useLogin()
  const { t, i18n } = useTranslation()
  const [showSpinner, shouldShowSpinner] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const creditBundles = business ? business.bookingOptions.creditBundles : []
  const [createOrderMutation, { loading: creatingOrder }] = useMutation(
    CREATE_CREDIT_ORDER_MUTATION
  )

  const close = () => {
    navigate(SCHEDULE_PATH)
  }

  const createOrder = async (bundle: CreditBundle) => {
    const createOrderResult = await createOrderMutation({
      variables: {
        creditBundle: creditBundleInputFromCreditBundle(bundle),
        checkoutUrls: {
          success: window.location.origin + CREDITS_CHECKOUT_SUCCESS_PATH,
          cancel: window.location.origin + CREDITS_CHECKOUT_CANCEL_PATH,
          return: null,
        },
      },
    })

    return createOrderResult.data.createCreditOrder
  }

  const completeOrder = (creditOrderResponse: CreditOrderResponse) => {
    shouldShowSpinner(false)

    window.location.href = creditOrderResponse.paymentRedirectUrl
  }

  const handleCheckoutError = ({
    apolloError,
    message,
  }: {
    apolloError?: ApolloError
    message?: string
  }) => {
    let errorMessage = message ?? t('error.generic')

    if (apolloError && apolloError.message === 'Unauthorized.') {
      navigate(LOGIN_PATH, {
        replace: true,
        state: {
          referrerPath: location.pathname,
          sessionExpired: true,
        },
      })
    } else if (
      apolloError &&
      apolloError?.graphQLErrors.length > 0 &&
      apolloError.graphQLErrors[0].extensions
    ) {
      console.error('graphQLError', apolloError.graphQLErrors[0].extensions)

      const errorCode = apolloError.graphQLErrors[0].extensions.code
      switch (errorCode) {
        case ERROR_PAYMENT_UNAVAILABLE:
          errorMessage = t('error.paymentUnavailable')
          break
        case ERROR_CREDIT_BUNDLE_DISCONTINUED:
          errorMessage = t('credits.bundleUnavailable')
          break
        default:
          Sentry.captureException(apolloError)
      }
    }

    shouldShowSpinner(false)
    setErrorMessage(errorMessage)
  }

  const purchaseBundle = async (bundle: CreditBundle) => {
    if (!user) {
      navigate(LOGIN_PATH, {
        state: {
          referrerPath: location.pathname,
        },
      })
      return
    }

    if (!user.email) {
      navigate(CREDITS_CHECKOUT_PATH, { state: { bundle } })
      return
    }

    shouldShowSpinner(true)
    setErrorMessage(null)

    try {
      completeOrder(await createOrder(bundle))
    } catch (error: any) {
      handleCheckoutError({ apolloError: error })
    }
  }

  const renderBundles = () => {
    if (creditBundles.length > 0) {
      return (
        <>
          <div className="mb-8 mt-4 whitespace-pre-line text-center font-extralight">
            {t('credits.prompt')}
          </div>

          {creditBundles.map((bundle: CreditBundle) => {
            return (
              <div
                key={bundle.id}
                className="mx-4 my-4 rounded-xl bg-gray-100 p-6 text-center md:last:mb-0"
              >
                <div className="mb-12 text-3xl font-medium">{bundle.name}</div>

                <div className="my-4">
                  <div className="font-medium">
                    {t('credits.totalCredits', {
                      count: bundle.totalCredits,
                    })}
                  </div>
                  <div className="text-3xl">
                    {formatMoney(bundle.price, i18n.resolvedLanguage)}
                  </div>
                </div>

                <button
                  onClick={() => purchaseBundle(bundle)}
                  disabled={creatingOrder}
                  className="mb-1 mt-4 rounded-md bg-primary px-6 py-2 text-lg font-semibold text-white"
                >
                  {t('button.purchase')}
                </button>

                {bundle.creditValidityMonths && (
                  <div>
                    {t('credits.validFor', {
                      count: bundle.creditValidityMonths,
                    })}
                  </div>
                )}
                {bundle.description && (
                  <div className="mt-6 font-extralight">
                    {bundle.description}
                  </div>
                )}
              </div>
            )
          })}
        </>
      )
    } else {
      return <div className="text-center">{t('credits.noBundles')}</div>
    }
  }

  return (
    <PageLayout headerProps={{ showBasketIcon: true }}>
      <div className="relative">
        <CloseIcon onClick={() => close()} />
        <div className="mx-auto flex flex-col md:my-6">
          <div className="border-gray mx-auto flex w-full flex-col rounded-lg bg-white pt-12 text-center md:max-w-lg md:border md:pb-4 md:pt-6">
            <CloseIcon onClick={close} />
            <div className="absolute left-0 top-0 mt-2 md:hidden">
              <UserProfileButton />
            </div>
            <h1 className="mb-2 mt-4 text-center text-2xl font-black">
              {t('title.credits')}
            </h1>
            <Link
              to={SCHEDULE_PATH}
              className="mb-7 mt-2 hidden text-primary underline md:block"
            >
              {t('button.viewSchedule')}
            </Link>

            <div className="mx-4">
              <ErrorMessage
                visible={errorMessage !== null}
                message={errorMessage || ''}
              />
            </div>

            <div>{renderBundles()}</div>

            <OverlaySpinner
              visible={showSpinner}
              title={t('spinner.titleInProgress', {
                action: t('checkout'),
              })}
              subtitle={t('spinner.subtitlePleaseWait')}
            />
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default CreditsView

import { useLazyQuery } from '@apollo/client'
import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { SHARED_EVENT_QUERY } from 'graphql/Events'
import { ScheduleLocationState } from 'pages/schedule/Schedule'
import CookieNotice from 'shared/components/CookieNotice'
import PageLayout from 'shared/components/PageLayout'
import View from './EventDetails'

const EventDetails = () => {
  const navigate = useNavigate()
  const locationState = useLocation() as {
    state?: ScheduleLocationState
  }
  const [event, setEvent] = useState(locationState.state?.event)
  const [sharedEventQuery] = useLazyQuery(SHARED_EVENT_QUERY)

  useEffect(() => {
    const getEvent = async (shareableId: string) => {
      try {
        const sharedEventResult = await sharedEventQuery({
          variables: {
            shareableId,
          },
        })

        if (sharedEventResult.data.sharedEvent) {
          setEvent(sharedEventResult.data.sharedEvent)
          return
        }
      } catch (error: any) {
        console.error(error)
      }

      navigate('/')
    }

    if (!event) {
      const pathComponents = window.location.pathname.split('/')
      const shareableId = pathComponents[pathComponents.length - 1]
      getEvent(shareableId)
    }
  }, [event, navigate, sharedEventQuery])

  return (
    <PageLayout headerProps={{ showBasketIcon: true }} pageLoading={!event}>
      <View event={event!} scheduleLocationState={locationState.state} />
      <CookieNotice />
    </PageLayout>
  )
}

export default EventDetails

import { useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { LOGOUT_PATH, SCHEDULE_PATH } from 'Routes'
import { CartContext } from 'context/CartProvider'
import { useLogin } from 'login/use-login'
import CloseIcon from 'shared/components/CloseIcon'
import PageLayout from 'shared/components/PageLayout'
import UserProfileButton from 'shared/components/UserProfileButton'

interface LocationState {
  orderReference: string
  paymentInstructions?: string
}

const Confirmation = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const state = (location.state as LocationState) || {}
  const { user, refetchUser } = useLogin()
  const { orderReference, paymentInstructions } = state
  const { emptyCart } = useContext(CartContext)

  useEffect(() => {
    emptyCart()

    if (user) {
      refetchUser()
    }

    if (!orderReference) {
      close()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const close = (destination?: string) => {
    navigate(destination || '/', {
      replace: true,
    })
  }

  return (
    <PageLayout>
      <div className="relative">
        <CloseIcon onClick={() => close()} />
        <div className="absolute left-0 top-0 mt-2 md:hidden">
          <UserProfileButton />
        </div>
        <div className="mx-auto flex flex-col md:mt-6">
          <div className="border-gray mx-auto flex w-full flex-col rounded-lg bg-white pt-20 text-center md:max-w-lg md:border md:p-6">
            <div className="single-word-lines md:word-spacing-normal mb-6 text-2xl font-light md:mb-1">
              {t('bookingConfirmation.bookingConfirmed')}
            </div>
            <Link
              to="/"
              className="mb-7 hidden text-primary underline md:block"
            >
              {t('bookingConfirmation.returnToSchedule')}
            </Link>
            <div className="text-primary">
              <svg
                className="inline h-28 w-28"
                fill="none"
                height="15"
                viewBox="0 0 15 15"
                width="15"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clipRule="evenodd"
                  d="M0 7.5C0 3.35786 3.35786 0 7.5 0C11.6421 0 15 3.35786 15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5ZM7.0718 10.7106L11.3905 5.31232L10.6096 4.68762L6.92825 9.2893L4.32012 7.11586L3.67993 7.88408L7.0718 10.7106Z"
                  fill="currentColor"
                  fillRule="evenodd"
                />
              </svg>
            </div>
            <div className="my-6 md:mb-0">{t('confirmation.emailMessage')}</div>
            <div className="md:mb-6">
              {t('confirmation.orderReferenceMessage')}{' '}
              <span
                className="font-bold text-primary"
                data-testid="order-reference"
              >
                {orderReference}
              </span>
            </div>

            <div className="hidden md:block">
              {user && (
                <button
                  onClick={() => close(LOGOUT_PATH)}
                  className="mx-auto mb-6 block text-gray-400 underline"
                >
                  {t('login.logOut')}
                </button>
              )}
            </div>

            {paymentInstructions && (
              <div className="mt-4 rounded bg-lightGray p-2 font-medium">
                <div className="mb-2">
                  {t('bookingConfirmation.payment.heading')}:
                </div>
                <div
                  className="font-normal"
                  dangerouslySetInnerHTML={{ __html: paymentInstructions }}
                ></div>
              </div>
            )}

            <div className="md:hidden">
              <button
                onClick={() => close(SCHEDULE_PATH)}
                className="mx-auto mt-12 block text-primary underline"
              >
                {t('bookingConfirmation.returnToSchedule')}
              </button>
            </div>

            <div className="md:hidden">
              {user && (
                <button
                  onClick={() => close(LOGOUT_PATH)}
                  className="mx-auto mt-6 block text-gray-400 underline"
                >
                  {t('login.logOut')}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default Confirmation

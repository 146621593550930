import {
  ApolloProvider,
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  HttpLink,
  from,
} from '@apollo/client'
import { createFragmentRegistry } from '@apollo/client/cache'
// import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev'
import { ReactNode } from 'react'
import { fragments } from './fragments'

// loadDevMessages()
// loadErrorMessages()

const httpLink = new HttpLink({
  uri: import.meta.env.VITE_APP_BOOKABLE_API,
  credentials: 'include',
})

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => {
    const additionalHeaders: Record<string, string> = {
      'X-Bookable-API': 'booking',
    }

    return {
      headers: {
        ...headers,
        ...additionalHeaders,
      },
    }
  })

  return forward(operation)
})

const client = new ApolloClient({
  uri: import.meta.env.VITE_APP_BOOKABLE_API,
  cache: new InMemoryCache({
    typePolicies: {
      Event: {
        // Recurring events share the same ID so we need to use multiple
        // fields to generate cache key
        keyFields: ['id', 'startTime'],
      },
    },
    fragments: createFragmentRegistry(fragments),
  }),
  link: from([authMiddleware, httpLink]),
})

const GqlClient = ({ children }: { children: ReactNode }) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default GqlClient

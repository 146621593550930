import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

const ErrorMessage = ({
  visible,
  message,
}: {
  visible: boolean
  message: string | ReactNode | null
}) => {
  const { t } = useTranslation()

  if (!visible || !message) {
    return null
  }

  return (
    <div
      className="flex items-stretch rounded text-primary"
      data-testid="error-message"
    >
      <div className="w-4 rounded-l border-primary bg-primary"></div>
      <div className="flex flex-grow rounded-r border-[1.5px] border-primary">
        <div className="flex flex-col px-4 py-3">
          <div className="mb-1 font-black">{t('error')}</div>
          <div className="text-normal ">{message}</div>
        </div>
      </div>
    </div>
  )
}

export default ErrorMessage

import { useMutation, ApolloError } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import {
  LOGIN_PATH,
  SCHEDULE_PATH,
  MEMBERSHIP_CHECKOUT_PATH,
  MEMBERSHIP_CHECKOUT_SUCCESS_PATH,
  MEMBERSHIP_CHECKOUT_CANCEL_PATH,
} from 'Routes'
import { useBusiness } from 'business/use-business'
import { MembershipPlan } from 'generated/graphql'
import { CREATE_MEMBERSHIP_CHECKOUT_URL_MUTATION } from 'graphql/Membership'
import {
  ERROR_PAYMENT_UNAVAILABLE,
  ERROR_MEMBERSHIP_DISCONTINUED,
} from 'graphql/errors'
import { formatMoney } from 'lib/moneyUtils'
import { useLogin } from 'login/use-login'
import CloseIcon from 'shared/components/CloseIcon'
import ErrorMessage from 'shared/components/ErrorMessage'
import OverlaySpinner from 'shared/components/OverlaySpinner'
import PageLayout from 'shared/components/PageLayout'
import UserProfileButton from 'shared/components/UserProfileButton'
import { defaultPriceForPlan } from './helpers/default-price-for-plan'

const Memberships = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { business } = useBusiness()
  const { user } = useLogin()
  const { t, i18n } = useTranslation()
  const [showSpinner, shouldShowSpinner] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const membershipPlans = business
    ? business.bookingOptions.membershipPlans
    : []
  const [createCheckoutUrlMutation, { loading: creatingCheckoutUrl }] =
    useMutation(CREATE_MEMBERSHIP_CHECKOUT_URL_MUTATION)

  const close = () => {
    navigate(SCHEDULE_PATH)
  }

  const createCheckoutUrl = async (membershipPlan: MembershipPlan) => {
    const createCheckoutUrlResult = await createCheckoutUrlMutation({
      variables: {
        membershipPlanId: membershipPlan.id,
        checkoutUrls: {
          success: window.location.origin + MEMBERSHIP_CHECKOUT_SUCCESS_PATH,
          cancel: window.location.origin + MEMBERSHIP_CHECKOUT_CANCEL_PATH,
          return: null,
        },
      },
    })

    return createCheckoutUrlResult.data.createMembershipCheckoutUrl.url
  }

  const startCheckout = (checkoutUrl: string) => {
    shouldShowSpinner(false)

    window.location.href = checkoutUrl
  }

  const handleCheckoutError = ({
    apolloError,
    message,
  }: {
    apolloError?: ApolloError
    message?: string
  }) => {
    let errorMessage = message ?? t('error.generic')

    if (apolloError && apolloError.message === 'Unauthorized.') {
      navigate(LOGIN_PATH, {
        replace: true,
        state: {
          referrerPath: location.pathname,
          sessionExpired: true,
        },
      })
    } else if (
      apolloError &&
      apolloError?.graphQLErrors.length > 0 &&
      apolloError.graphQLErrors[0].extensions
    ) {
      console.error('graphQLError', apolloError.graphQLErrors[0].extensions)

      const errorCode = apolloError.graphQLErrors[0].extensions.code
      switch (errorCode) {
        case ERROR_PAYMENT_UNAVAILABLE:
          errorMessage = t('error.paymentUnavailable')
          break
        case ERROR_MEMBERSHIP_DISCONTINUED:
          errorMessage = t('memberships.planUnavailable')
          break
        default:
          Sentry.captureException(apolloError)
      }
    }

    shouldShowSpinner(false)
    setErrorMessage(errorMessage)
  }

  const purchaseMembership = async (plan: MembershipPlan) => {
    if (!user) {
      navigate(LOGIN_PATH, {
        state: {
          referrerPath: location.pathname,
        },
      })
      return
    }

    if (!user.email) {
      navigate(MEMBERSHIP_CHECKOUT_PATH, { state: { membershipPlan: plan } })
      return
    }

    shouldShowSpinner(true)
    setErrorMessage(null)

    try {
      startCheckout(await createCheckoutUrl(plan))
    } catch (error: any) {
      handleCheckoutError({ apolloError: error })
    }
  }

  const renderMembershipOptions = () => {
    if (membershipPlans.length > 0) {
      return (
        <>
          <div className="mb-8 mt-4 whitespace-pre-line px-8 text-center font-extralight">
            {t('memberships.prompt')}
          </div>

          {membershipPlans.map((plan: MembershipPlan) => {
            const membershipPlanPrice = defaultPriceForPlan(plan)

            return (
              <div
                key={plan.id}
                className="mx-4 my-4 rounded-xl bg-gray-100 p-6 text-center md:last:mb-0"
              >
                <div className="mb-12 text-3xl font-medium">{plan.name}</div>

                <div className="my-4">
                  <div className="text-3xl">
                    {formatMoney(
                      membershipPlanPrice!.price,
                      i18n.resolvedLanguage
                    )}
                    {` / ${t('month')}`}
                  </div>
                </div>

                <button
                  onClick={() => purchaseMembership(plan)}
                  disabled={creatingCheckoutUrl}
                  className="mb-1 mt-4 rounded-md bg-primary px-6 py-2 text-lg font-semibold text-white"
                >
                  {t('button.purchase')}
                </button>
                <div>
                  {t('memberships.bookingsPerMonth', {
                    count: plan.bookingAllowance,
                  })}
                </div>

                <div className="mt-6 font-extralight">{plan.description}</div>
              </div>
            )
          })}
        </>
      )
    } else {
      return <div className="text-center">{t('memberships.noPlans')}</div>
    }
  }

  return (
    <PageLayout headerProps={{ showBasketIcon: true }}>
      <div className="relative">
        <CloseIcon onClick={() => close()} />
        <div className="mx-auto flex flex-col md:my-6">
          <div className="border-gray mx-auto flex w-full flex-col rounded-lg bg-white pt-12 text-center md:max-w-lg md:border md:pb-4 md:pt-6">
            <CloseIcon onClick={close} />
            <div className="absolute left-0 top-0 mt-2 md:hidden">
              <UserProfileButton />
            </div>
            <h1 className="mb-2 mt-4 text-center text-2xl font-black">
              {t('title.memberships')}
            </h1>
            <Link
              to={SCHEDULE_PATH}
              className="mb-7 mt-2 hidden text-primary underline md:block"
            >
              {t('button.viewSchedule')}
            </Link>

            <div className="mx-4">
              <ErrorMessage
                visible={errorMessage !== null}
                message={errorMessage || ''}
              />
            </div>

            <div>{renderMembershipOptions()}</div>

            <OverlaySpinner
              visible={showSpinner}
              title={t('spinner.titleInProgress', {
                action: t('checkout'),
              })}
              subtitle={t('spinner.subtitlePleaseWait')}
            />
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default Memberships

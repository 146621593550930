import { useEffect, createContext, ReactNode } from 'react'
import { useBusinessManager } from 'business/use-business-manager'

export const BusinessContext = createContext<
  ReturnType<typeof useBusinessManager>
>({
  start: () => {},
  refetch: () => {
    return Promise.resolve()
  },
  loading: false,
  business: false,
  errors: [],
})

export const BusinessProvider = ({ children }: { children: ReactNode }) => {
  const businessManager = useBusinessManager()

  useEffect(() => {
    businessManager.start()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <BusinessContext.Provider value={businessManager}>
      {children}
    </BusinessContext.Provider>
  )
}

import { parsePhoneNumber } from 'libphonenumber-js'
import isEmail from 'validator/lib/isEmail'
import isEmpty from 'validator/lib/isEmpty'
import { Business, User } from 'generated/graphql'
import { countryCodes, createPhoneCountryCode } from 'lib/phoneUtils'
import {
  CHECKOUT_FORM_EMAIL,
  CHECKOUT_FORM_FIRST_NAME,
  CHECKOUT_FORM_LAST_NAME,
  CHECKOUT_FORM_PHONE_COUNTRY_CODE,
  CHECKOUT_FORM_PHONE,
} from 'shared/constants/checkout'
import { CheckoutFormData } from 'shared/constants/checkout'

export interface CheckoutFormValidationErrors {
  firstName: string | null
  lastName: string | null
  email: string | null
  phone: string | null
}

interface IPhoneNumberDetails {
  countryCode: string
  number: string
  full: string
}

interface CheckoutFormValidationResponse {
  valid: boolean
  validationErrors: CheckoutFormValidationErrors
  formData: {
    firstName: string
    lastName: string
    email: string
    phone: IPhoneNumberDetails
  } | null
}

const phoneNumberIsValid = (number: string): boolean => {
  try {
    const phoneNumber = parsePhoneNumber(number)
    return phoneNumber.isValid()
  } catch {
    return false
  }
}

const normalizePhoneNumber = ({
  countryCode,
  phoneNumber,
}: {
  countryCode: string
  phoneNumber: string
}) => {
  const trunkCode = countryCodes[countryCode].trunkCode
  let normalisedNumber = phoneNumber.replace(/[^0-9]/g, '')

  normalisedNumber =
    trunkCode && normalisedNumber.startsWith(trunkCode)
      ? normalisedNumber.substring(trunkCode.length, normalisedNumber.length)
      : normalisedNumber

  return {
    countryCode: `${countryCode} +${countryCodes[countryCode].dialingCode}`,
    number: normalisedNumber,
    full: `+${countryCodes[countryCode].dialingCode}${normalisedNumber}`,
  }
}

const sanitizeEmail = (email: string): string => {
  if (email.endsWith('.con')) {
    return email.replace(/.$/, 'm')
  } else {
    return email
  }
}

export const checkoutFormInitialValue = (
  cookieConsent: boolean,
  cookiedGuest: { [id: string]: string },
  param: string,
  userDetails: User | false
): string => {
  if (userDetails && userDetails[param as keyof User]) {
    switch (param) {
      case CHECKOUT_FORM_PHONE_COUNTRY_CODE:
        return userDetails[param as keyof User]
          ? (userDetails[param as keyof User] as string).substring(0, 2)
          : ''
      default:
        return userDetails[param as keyof User] as string
    }
  } else {
    return cookieConsent && cookiedGuest && cookiedGuest[param]
      ? cookiedGuest[param]
      : ''
  }
}

export const initialCheckoutFormData = ({
  user,
  business,
  cookieConsent,
  cookiedGuest,
}: {
  user: User | false
  business: Business
  cookieConsent: boolean
  cookiedGuest?: any
}) => {
  const initialPhoneCountryCode = checkoutFormInitialValue(
    cookieConsent,
    cookiedGuest,
    CHECKOUT_FORM_PHONE_COUNTRY_CODE,
    user
  )

  return {
    firstName: checkoutFormInitialValue(
      cookieConsent,
      cookiedGuest,
      CHECKOUT_FORM_FIRST_NAME,
      user
    ),
    lastName: checkoutFormInitialValue(
      cookieConsent,
      cookiedGuest,
      CHECKOUT_FORM_LAST_NAME,
      user
    ),
    email: checkoutFormInitialValue(
      cookieConsent,
      cookiedGuest,
      CHECKOUT_FORM_EMAIL,
      user
    ),
    phoneCountryCode: createPhoneCountryCode(
      initialPhoneCountryCode === ''
        ? business.phoneCountryCode
          ? business.phoneCountryCode.substring(0, 2)
          : undefined
        : initialPhoneCountryCode.substring(0, 2)
    ),
    phoneNumber: checkoutFormInitialValue(
      cookieConsent,
      cookiedGuest,
      CHECKOUT_FORM_PHONE,
      user
    ),
  }
}

export const validateCheckoutForm = (
  formData: CheckoutFormData
): CheckoutFormValidationResponse => {
  const phone = normalizePhoneNumber({
    countryCode: formData.phoneCountryCode.substring(0, 2),
    phoneNumber: formData.phoneNumber,
  })

  const response: CheckoutFormValidationResponse = {
    valid: false,
    validationErrors: {
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
    },
    formData: null,
  }

  if (isEmpty(formData.firstName)) {
    response.validationErrors.firstName = 'validationError.firstName.empty'
  } else if (isEmpty(formData.lastName)) {
    response.validationErrors.lastName = 'validationError.lastName.empty'
  } else if (isEmpty(formData.email)) {
    response.validationErrors.email = 'validationError.email.empty'
  } else if (!isEmail(formData.email)) {
    response.validationErrors.email = 'validationError.email.invalid'
  } else if (isEmpty(formData.phoneNumber)) {
    response.validationErrors.phone = 'validationError.phone.empty'
  } else if (!phoneNumberIsValid(phone.full)) {
    response.validationErrors.phone = 'validationError.phone.invalid'
  } else {
    response.valid = true
    response.formData = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: sanitizeEmail(formData.email),
      phone,
    }
  }

  return response
}
